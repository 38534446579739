/* eslint-disable react-hooks/exhaustive-deps */
import AssessmentList from "./Assessments/AssessmentList/AssessmentList";
import AssessmentDetail from "./Assessments/AssessmentDetail/AssessmentDetail";
import { useEffect, useState } from "react";
import QuestionBankList from "./QuestionBanks/QuestionBankList/QuestionBankList";
import QuestionBankQuestions from "./QuestionBanks/QuestionBankDetail/QuestionBankDetail";
import { motion } from "framer-motion";
import React from "react";
import { showVariants } from "../../constants/general";
import PageLayout from "../../layouts/PageLayout";
import { useGRCStore } from "src/stores/grc";

const QuestionnaireRFP = () => {
  const { selectedGRCAssesment } = useGRCStore();

  const [selectedTab, setSelectedTab] = useState<string>("assessments");

  useEffect(() => {
    sessionStorage.page = "Questionnaire & RFP";
  }, []);

  return (
    <PageLayout>
      <motion.main
        variants={showVariants}
        initial="hidden"
        animate="visible"
        className="flex flex-col flex-grow gap-5 p-4 w-full min-h-full text-base"
      >
        <nav className="flex flex-wrap items-center text-base">
          {["assessments", "question banks"].map((tab) => {
            return (
              <button
                key={tab}
                className={`px-8 py-2 text-center capitalize border-b-2 ${
                  selectedTab === tab
                    ? "dark:text-white dark:border-blue-600"
                    : "dark:text-checkbox dark:hover:text-white dark:border-checkbox"
                }`}
                onClick={() => setSelectedTab(tab)}
              >
                {tab}
              </button>
            );
          })}
        </nav>
        {selectedTab === "assessments" ? (
          !sessionStorage.assessment_id ? (
            <AssessmentList />
          ) : (
            <AssessmentDetail />
          )
        ) : selectedTab === "question banks" ? (
          !sessionStorage.question_bank_id ? (
            <QuestionBankList />
          ) : (
            <QuestionBankQuestions />
          )
        ) : null}
      </motion.main>
    </PageLayout>
  );
};

export default QuestionnaireRFP;
