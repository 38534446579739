/* eslint-disable no-restricted-globals */
import DropdownLayout from "../../../layouts/DropdownLayout";
import { KeyStringVal } from "src/types/general";

const DocumentNameFilter = ({
  inputs,
  setInputs,
  list,
}: {
  inputs: KeyStringVal;
  setInputs: (inputs: KeyStringVal) => void;
  list: KeyStringVal[];
}) => {
  return (
    <DropdownLayout
      label="Document Name"
      value={inputs.source_document_name}
      width="w-[30rem]"
      placeholder="Select document"
    >
      {list?.map((document: KeyStringVal, index: number) => {
        const sourceDocID = document.id || document.policy_id;
        return (
          <button
            key={index}
            className={`relative px-4 py-1 w-full break-words text-left dark:hover:bg-gray-600/70 ${
              inputs.source_document_id === sourceDocID ? "dark:bg-filter" : ""
            } duration-100`}
            onClick={() =>
              setInputs({
                ...inputs,
                source_document_name: document.name || document.policy_name,
                source_document_id: sourceDocID,
                source_version_id: document.latest_version_id || "",
                section_id: "",
                source_section_id: "",
                source_section_title: "",
                source_section_generated_id: "",
                content: "",
              })
            }
          >
            <p>{document.name || document.policy_name}</p>
          </button>
        );
      })}
    </DropdownLayout>
  );
};

export default DocumentNameFilter;
