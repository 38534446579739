import React, { useState } from "react";
import RegularInput from "src/components/Input/RegularInput";
import ModalLayout from "src/layouts/ModalLayout";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong, faUpload } from "@fortawesome/free-solid-svg-icons";
import { UploadSOPVersion } from "src/services/business-continuity/sop";
import Tags from "./Tags";
import MultipleFileInput from "src/components/Input/MultipleFileInput";

const NewSOP = () => {
  const navigate = useNavigate();

  const [show, setShow] = useState<boolean>(false);
  const [newSOPID, setNewSOPID] = useState<string>("");
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [inputs, setInputs] = useState<any>({
    sop_id: "",
    sop_name: "",
    sop_version: "",
    files: [],
    file_uri: "",
    selected_tag: "",
    entered_tag: "",
  });

  const addSOP = UploadSOPVersion();

  const handleOnClose = () => setShow(false);
  const handleOnCloseConfirmation = () => setNewSOPID("");

  return (
    <>
      <button
        className="place-self-end blue-button"
        onClick={() => {
          setShow(true);
          setSelectedOption("Upload a new procedure");
          setInputs({
            sop_id: "",
            sop_name: "",
            sop_version: "",
            files: [],
            file_uri: "",
            selected_tag: "",
            entered_tag: "",
          });
        }}
      >
        <FontAwesomeIcon icon={faUpload} />
        <h4>Upload Procedure</h4>
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <>
          {newSOPID !== "" ? (
            <section className="grid content-start gap-10 h-full">
              <article className="flex items-center gap-2">
                <img
                  src="/general/checkmark.svg"
                  alt="checkmark"
                  className="w-12 h-12 mx-auto"
                />
                <h3 className="text-subt1-semi">
                  New Procedure has been uploaded!
                </h3>
              </article>
              <article className="flex items-center gap-2">
                <h4>
                  Go to Procedure
                  <button
                    className="p-1 dark:hover:bg-gray-700/70 duration-100 rounded-full"
                    onClick={() => {
                      navigate(
                        `/business-continuity/sop/details?sop_id=${newSOPID}&sop_version_id=${addSOP?.data?.version_id}`
                      );
                      handleOnCloseConfirmation();
                      handleOnClose();
                    }}
                  >
                    <FontAwesomeIcon icon={faArrowRightLong} />
                  </button>
                </h4>
              </article>
            </section>
          ) : (
            <section className="grid content-start gap-5 h-full overflow-auto scrollbar">
              <h3 className="flex items-center gap-2 text-t1-semi">
                Upload Procedure
              </h3>
              <nav className="flex items-center gap-5 mx-1">
                {["Upload a new procedure", "Upload multiple procedures"].map(
                  (option, index) => {
                    return (
                      <article key={index} className="flex items-center gap-2">
                        <input
                          type="radio"
                          className="form-radio w-4 h-4 dark:ring-0 dark:text-blue-500 dark:focus:border-blue-600 focus:ring dark:focus:ring-offset-0 dark:focus:ring-blue-600 focus:ring-opacity-50 rounded-full"
                          checked={selectedOption === option}
                          onChange={() => {
                            setSelectedOption(option);
                            if (
                              selectedOption === "Upload multiple procedures" &&
                              option !== "Upload multiple procedures"
                            )
                              setInputs({ ...inputs, files: [] });
                          }}
                        />
                        {option}
                      </article>
                    );
                  }
                )}
              </nav>
              {selectedOption !== "" && (
                <>
                  {selectedOption !== "Upload multiple procedures" && (
                    <section className="grid md:grid-cols-2 gap-10">
                      <RegularInput
                        label="Procedure Name"
                        keyName="sop_name"
                        inputs={inputs}
                        setInputs={setInputs}
                        required
                      />
                      <RegularInput
                        label="Procedure Version"
                        keyName="sop_version"
                        inputs={inputs}
                        setInputs={setInputs}
                        required
                      />
                    </section>
                  )}
                  <Tags inputs={inputs} setInputs={setInputs} />
                  {selectedOption !== "Upload multiple procedures" && (
                    <RegularInput
                      label="File URL (optional)"
                      keyName="file_uri"
                      inputs={inputs}
                      setInputs={setInputs}
                    />
                  )}
                  <MultipleFileInput
                    label="Procedures"
                    keyName="files"
                    types={["pdf"]}
                    inputs={inputs}
                    setInputs={setInputs}
                  />
                  <article className="flex items-center place-content-end gap-5">
                    <button className="black-button" onClick={handleOnClose}>
                      Cancel
                    </button>
                    <button
                      disabled={
                        (inputs.files.length <= 1 &&
                          (inputs.sop_name === "" ||
                            inputs.sop_version === "")) ||
                        (inputs.files.length === 0 && inputs.file_uri === "")
                      }
                      className="blue-button"
                      onClick={() => {
                        const formData = new FormData();

                        if (inputs.sop_name !== "")
                          formData.append("sop_name", inputs.sop_name);
                        if (inputs.sop_version !== "")
                          formData.append(
                            "bcm_sop_version",
                            inputs.sop_version
                          );
                        if (inputs.selected_tag || inputs.entered_tag)
                          formData.append(
                            "tag_name",
                            inputs.selected_tag !== ""
                              ? inputs.selected_tag
                              : inputs.entered_tag
                          );
                        if (inputs.files.length > 0)
                          inputs.files.forEach((file: any) =>
                            formData.append("files", file)
                          );
                        if (inputs.file_uri)
                          formData.append("file_uri", inputs.file_uri);

                        addSOP.mutate({
                          formData: formData,
                        });
                        handleOnClose();
                      }}
                    >
                      Done
                    </button>
                  </article>
                </>
              )}
            </section>
          )}
        </>
      </ModalLayout>
    </>
  );
};

export default NewSOP;
