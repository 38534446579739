import React from "react";
import ThirdPartyControlFilter from "src/components/Filter/ThirdPartyRisk/ThirdPartyControlFilter";
import { GetControlFilters } from "../../../services/third-party-risk/assessments/vendor-assessment";

const ControlFilters = ({
  reviewID,
  controls,
  controlFilters,
  setControlFilters,
}: {
  reviewID: string;
  controls: any;
  controlFilters: any;
  setControlFilters: any;
}) => {
  const { data: filters } = GetControlFilters(reviewID);

  const handleClear = () =>
    setControlFilters({
      context: [],
      domain: [],
      sub_domain: [],
      level: [],
    });

  const handleReset = () => {
    handleClear();
    if (controls.data)
      controls.mutate({
        context: [],
        domain: [],
        sub_domain: [],
        level: [],
      });
  };

  return (
    <>
      {controlFilters && (
        <article className="grid gap-3 text-sm">
          {filters && (
            <article className="flex items-center gap-5 divide-x dark:divide-checkbox">
              <button
                disabled={
                  controlFilters.context.length === filters.context?.length &&
                  controlFilters.domain.length === filters.domain?.length &&
                  controlFilters.sub_domain.length ===
                    filters.sub_domain?.length &&
                  controlFilters.level.length === filters.level?.length
                }
                className="dark:disabled:text-filter dark:hover:text-checkbox/60 duration-100"
                onClick={() =>
                  setControlFilters({
                    context: filters.context || [],
                    domain: filters.domain || [],
                    sub_domain: filters.sub_domain || [],
                    level: filters.level || [],
                  })
                }
              >
                Select All
              </button>
              <button
                disabled={Object.values(controlFilters).every(
                  (filter: string[]) => !filter.length
                )}
                className="pl-5 dark:disabled:text-filter dark:hover:text-checkbox/60 duration-100"
                onClick={handleClear}
              >
                Deselect All
              </button>
            </article>
          )}
          <ThirdPartyControlFilter
            reviewID={reviewID}
            label="Context"
            keyName="context"
            inputs={controlFilters}
            setInputs={setControlFilters}
          />
          <ThirdPartyControlFilter
            reviewID={reviewID}
            label="Category"
            keyName="domain"
            inputs={controlFilters}
            setInputs={setControlFilters}
          />
          <ThirdPartyControlFilter
            reviewID={reviewID}
            label="Sub Category"
            keyName="sub_domain"
            inputs={controlFilters}
            setInputs={setControlFilters}
          />
          <ThirdPartyControlFilter
            reviewID={reviewID}
            label="Level"
            keyName="level"
            inputs={controlFilters}
            setInputs={setControlFilters}
          />
          <article className="flex items-center gap-5">
            <button className="red-button" onClick={handleReset}>
              Reset
            </button>
            <button
              className="blue-button"
              onClick={() =>
                controls.mutate({
                  context: controlFilters.context,
                  domain: controlFilters.domain,
                  subDomain: controlFilters.sub_domain,
                  level: controlFilters.level,
                })
              }
            >
              Apply
            </button>
          </article>
        </article>
      )}
    </>
  );
};

export default ControlFilters;
