/* eslint-disable no-restricted-globals */
import DropdownLayout from "../../../layouts/DropdownLayout";
import { KeyStringVal } from "../../../types/general";
import { GetCustomQuestionSets } from "src/services/third-party-risk/questions/custom-question-sets";

const QuestionSetFilter = ({
  label,
  fileIndex,
  inputs,
  setInputs,
}: {
  label?: string;
  fileIndex: number;
  inputs: any;
  setInputs: any;
}) => {
  const { data: questionSets } = GetCustomQuestionSets();

  const questionSetID = inputs["question_sets"][fileIndex] || "";
  const questionSetName =
    questionSets?.data.find(
      (questionSet) => questionSet.generated_id === questionSetID
    )?.name || "";

  return (
    <DropdownLayout
      label={label}
      value={questionSetName}
      placeholder="Select"
      width="w-[18rem]"
      showAbove
    >
      {questionSets?.data.map((questionSet: KeyStringVal, index: number) => (
        <button
          key={index}
          className={`relative px-4 py-1 w-full text-left dark:hover:bg-gray-600/70 ${
            questionSetID === questionSet.generated_id ? "dark:bg-filter" : ""
          } duration-100`}
          onClick={() =>
            setInputs({
              ...inputs,
              assessments: inputs.assessments.map(
                (assessmentID, curFileIndex) => {
                  if (fileIndex === curFileIndex) {
                    return "";
                  }
                  return assessmentID;
                }
              ),
              question_sets: inputs.question_sets.map(
                (questionSetID, curFileIndex) => {
                  if (fileIndex === curFileIndex) {
                    return questionSet.generated_id;
                  }
                  return questionSetID;
                }
              ),
            })
          }
        >
          <p>{questionSet.name}</p>
        </button>
      ))}
    </DropdownLayout>
  );
};

export default QuestionSetFilter;
