/* eslint-disable react-hooks/exhaustive-deps */

import ColumnTypeFilter from "../../../../../components/Filter/ThirdPartyRisk/ColumnTypeFilter";
import TableLayout from "../../../../../layouts/TableLayout";

const Columns = ({
  headers,
  sheet,
  markedAsQuestionCol,
  setMarkedAsQuestionCol,
  markedAsAnswerCol,
  setMarkedAsAnswerCol,
  ignoreResponse,
  showGuidelines,
}: {
  headers: any;
  sheet: any;
  markedAsQuestionCol: any;
  setMarkedAsQuestionCol: any;
  markedAsAnswerCol: any;
  setMarkedAsAnswerCol: any;
  ignoreResponse?: boolean;
  showGuidelines?: boolean;
}) => {
  const filteredHeaders = headers[sheet];

  return (
    <section className="flex flex-col flex-grow gap-3">
      {ignoreResponse || filteredHeaders?.length > 1 ? (
        <TableLayout fullHeight>
          <thead className="sticky -top-1.5 text-b2-reg dark:bg-gray-900">
            <tr>
              {["Column Name", "Select Type"]?.map((col: string) => {
                return (
                  <th
                    scope="col"
                    key={col}
                    className="px-6 py-3 last:pr-10 w-full text-left font-semibold"
                  >
                    <h4 className="break-words">{col}</h4>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="text-b2-reg divide-y dark:divide-gray-700">
            {filteredHeaders?.map((col: any, colIndex: number) => {
              return (
                <tr
                  key={colIndex}
                  data-test="table-row"
                  className="relative p-5 gap-3 cursor-pointer break-words dark:bg-gray-800"
                >
                  <td className="relative px-6 py-3 last:pr-16 text-left break-words">
                    {col}
                  </td>
                  <td className="relative px-6 py-3 last:pr-16 text-left break-words">
                    <ColumnTypeFilter
                      sheet={sheet}
                      markedAsQuestionCol={markedAsQuestionCol}
                      setMarkedAsQuestionCol={setMarkedAsQuestionCol}
                      markedAsAnswerCol={markedAsAnswerCol}
                      setMarkedAsAnswerCol={setMarkedAsAnswerCol}
                      colIndex={colIndex}
                      ignoreResponse={ignoreResponse}
                      showGuidelines={showGuidelines}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </TableLayout>
      ) : (
        <span className="p-2 mt-2 break-words text-xs text-left dark:bg-inner border dark:border-error rounded-sm">
          Please upload a file with at least 2 columns
        </span>
      )}
    </section>
  );
};

export default Columns;
