import React from "react";
import {
  ActivateVendor,
  DeactivateVendor,
} from "../../../../../services/third-party-risk/vendors/vendors";
import { useGeneralStore } from "../../../../../stores/general";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/free-solid-svg-icons";
import { attributeColors } from "../../../../../constants/general";

const Status = React.memo(
  ({ vendorID, active }: { vendorID: string; active: boolean }) => {
    const { error } = useGeneralStore();

    const status = active ? "active" : "inactive";

    const activateVendor = ActivateVendor(vendorID);
    const deactivateVendor = DeactivateVendor(vendorID);

    const errorID = error.url
      .replace("/api/v1/grc/tprm/third-parties/", "")
      .replace("/deactivate", "");

    return (
      <>
        {error.message && errorID === vendorID ? (
          <span>
            <FontAwesomeIcon icon={faWarning} className="text-red-700" /> There
            are active assignments
          </span>
        ) : (
          <nav className="flex items-center gap-1 p-1 w-max dark:bg-gray-900 rounded-lg">
            {["active", "inactive"].map((tab) => {
              return (
                <button
                  key={tab}
                  className={`px-4 py-1 text-center capitalize ${
                    status === tab
                      ? `${attributeColors[status]}`
                      : "dark:text-gray-500 dark:hover:bg-gray-500/30 duration-100"
                  } rounded-lg`}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (tab === "inactive") deactivateVendor.mutate({});
                    else activateVendor.mutate({});
                  }}
                >
                  {tab}
                </button>
              );
            })}
          </nav>
        )}
      </>
    );
  }
);

export default Status;
