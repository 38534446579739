/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import { Fragment, useState } from "react";
import Policies from "../Policies";
import { GetPolicyGroups } from "src/services/regulation-policy/policy";
import { KeyStringVal } from "src/types/general";
import EditPolicyGroup from "./EditPolicyGroup";
import DeletePolicyGroup from "./DeletePolicyGroup";
import CreatePolicyGroup from "./CreatePolicyGroup";
import NewPolicy from "../NewPolicy";
import Loader from "src/components/Loader/Loader";
import CompareAgainstFramework from "../CompareAgainstFramework/CompareAgainstFramework";
import {
  faArrowRightLong,
  faMinus,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GeneratePolicy from "../GeneratePolicy";

const PolicyGroups = () => {
  const [selectedPolicyGroup, setSelectedPolicyGroup] =
    useState<boolean>(false);
  const [compareAgainst, setCompareAgainst] = useState<boolean>(false);
  const [showGeneratePolicy, setShowGeneratePolicy] = useState<boolean>(false);

  const { data: policyGroups, status: policyGroupStatus } = GetPolicyGroups();
  const policyGroup = policyGroups?.find(
    (group: KeyStringVal) =>
      group.policy_group_id === sessionStorage.selectedPolicyGroupID
  )?.title;

  return (
    <section className="flex flex-col flex-grow gap-5 py-4 w-full h-full">
      {sessionStorage.compareAgainstFramework ? (
        <CompareAgainstFramework
          compareAgainst={compareAgainst}
          setCompareAgainst={setCompareAgainst}
        />
      ) : !sessionStorage.selectedPolicyGroupID ? (
        <section className="flex flex-col flex-grow gap-5 w-full min-h-full">
          <header className="flex flex-wrap items-center justify-between gap-5 text-lg">
            <article className="flex items-center gap-4">
              <h4>
                Policy Groups{" "}
                <span className="dark:text-checkbox">
                  ({policyGroups?.length})
                </span>
              </h4>
              <CreatePolicyGroup />
            </article>
            <button
              className="blue-button"
              onClick={() => {
                sessionStorage.compareAgainstFramework = "true";
                setCompareAgainst(!compareAgainst);
              }}
            >
              Compare against Framework
            </button>
          </header>
          <article className="flex items-center gap-5 mx-auto">
            <NewPolicy />
            <button
              className="mx-auto blue-button"
              onClick={() => setShowGeneratePolicy(!showGeneratePolicy)}
            >
              <FontAwesomeIcon icon={showGeneratePolicy ? faMinus : faPlus} />
              <h4>Generate Policy</h4>
            </button>
          </article>
          {showGeneratePolicy ? (
            <GeneratePolicy
              selectedPolicyGroup={selectedPolicyGroup}
              setSelectedPolicyGroup={setSelectedPolicyGroup}
              setShowGeneratePolicy={setShowGeneratePolicy}
            />
          ) : (
            <>
              {policyGroupStatus === "loading" ? (
                <Loader />
              ) : policyGroups?.length > 0 ? (
                <ul className="flex flex-col flex-grow gap-5 pb-20">
                  {policyGroups?.map((policyGroup: KeyStringVal) => {
                    return (
                      <li
                        key={policyGroup.policy_group_id}
                        className="grid gap-3 p-5 dark:bg-gray-700 rounded-md"
                      >
                        <header className="flex items-start justify-between gap-20">
                          <article className="grid gap-2">
                            <article className="flex flex-wrap items-end gap-5">
                              <h4 className="text-xl">{policyGroup.title}</h4>
                              <button
                                className="px-4 py-1 w-max text-left dark:bg-gray-900 dark:hover:bg-gray-900/70 duration-100 rounded-full"
                                onClick={() => {
                                  setSelectedPolicyGroup(!selectedPolicyGroup);
                                  sessionStorage.selectedPolicyGroupID =
                                    policyGroup.policy_group_id;
                                  sessionStorage.selectedPolicyGroupName =
                                    policyGroup.title;
                                }}
                              >
                                View policies{" "}
                                <FontAwesomeIcon icon={faArrowRightLong} />{" "}
                              </button>
                            </article>
                            <p>{policyGroup.description}</p>
                          </article>
                          <article className="flex items-center gap-5 text-sm">
                            {!["default", "all"].includes(
                              policyGroup.title.toLowerCase()
                            ) && (
                              <>
                                <EditPolicyGroup
                                  policyGroupID={policyGroup.policy_group_id}
                                />
                                <DeletePolicyGroup
                                  policyGroupID={policyGroup.policy_group_id}
                                />
                              </>
                            )}
                          </article>
                        </header>
                      </li>
                    );
                  })}
                </ul>
              ) : (
                <section className="flex items-center place-content-center gap-10 w-full h-full">
                  <img
                    src="/grc/policies-placeholder.svg"
                    alt="policies placeholder"
                    className="w-40 h-40"
                  />
                  <article className="grid gap-3">
                    <h4 className="text-xl font-extrabold">Policies</h4>
                    <h4>No policy groups available</h4>
                  </article>
                </section>
              )}
            </>
          )}
        </section>
      ) : (
        <Policies
          policyGroup={policyGroup}
          selectedPolicyGroup={selectedPolicyGroup}
          setSelectedPolicyGroup={setSelectedPolicyGroup}
        />
      )}
    </section>
  );
};

export default PolicyGroups;
