import React, { useState } from "react";
import ModalLayout from "src/layouts/ModalLayout";
import { CloseInternalAudit } from "src/services/audit-management";
import { ExportInternalAudit } from "src/services/audit-management";

const CloseAudit = ({ auditID }: { auditID: string }) => {
  const [show, setShow] = useState<boolean>(false);
  const [triggerExport, setTriggerExport] = useState<boolean>(false);

  const closeAudit = CloseInternalAudit(auditID);
  const exportInternalAudit = ExportInternalAudit(auditID);

  const handleOnClose = () => setShow(false);

  return (
    <>
      <button className="blue-button" onClick={() => setShow(true)}>
        Close Audit
      </button>
      <ModalLayout showModal={show} onClose={() => setShow(false)}>
        <section className="grid gap-10">
          <h2 className="text-subt1-semi">Close Internal Audit</h2>
          <h4 className="text-b1-reg">
            Are you sure you want to close this internal audit?
          </h4>
          <article className="flex items-center gap-5 mx-auto">
            <input
              type="checkbox"
              checked={triggerExport}
              className="form-checkbox w-4 h-4 border-0 dark:focus:ring-0 dark:text-checkbox dark:focus:border-checkbox focus:ring dark:focus:ring-offset-0 dark:focus:ring-blue-500 focus:ring-opacity-50"
              onChange={() => setTriggerExport(!triggerExport)}
            />
            <label htmlFor="">Do you want to export?</label>
          </article>
          <section className="flex items-center place-content-end gap-5">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              className="red-button"
              onClick={() => {
                if (triggerExport) exportInternalAudit.mutate({});
                closeAudit.mutate({
                  auditID: auditID,
                });
                handleOnClose();
              }}
            >
              Close
            </button>
          </section>
        </section>
      </ModalLayout>
    </>
  );
};

export default CloseAudit;
