import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import RegularInput from "src/components/Input/RegularInput";
import { AddCustomQuestionSet } from "src/services/third-party-risk/questions/custom-question-sets";
import ModalLayout from "src/layouts/ModalLayout";

const CreateManualSet = ({
  inputs,
  setInputs,
  selectedQuestions,
}: {
  inputs: any;
  setInputs: (inputs: any) => void;
  selectedQuestions: string[];
}) => {
  const [show, setShow] = useState<boolean>(false);

  const createCustomQuestionSet = AddCustomQuestionSet();

  const handleOnClose = () => setShow(false);

  return (
    <>
      <button
        disabled={selectedQuestions.length === 0}
        className="black-button"
        onClick={() => {
          setShow(true);
          setInputs({
            name: "",
            description: "",
          });
        }}
      >
        <FontAwesomeIcon icon={faPlus} />
        <h4>Create Question Set</h4>
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="grid content-start gap-5 h-full">
          <h3 className="flex items-center gap-2 text-t1-semi">
            Create Question Set
          </h3>
          <RegularInput
            label="Name"
            keyName="name"
            inputs={inputs}
            setInputs={setInputs}
            required
          />
          <RegularInput
            label="Description"
            keyName="description"
            inputs={inputs}
            setInputs={setInputs}
          />
          <article className="flex items-center place-content-end gap-5">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={inputs.name === ""}
              className="blue-button"
              onClick={() => {
                createCustomQuestionSet.mutate({
                  name: inputs.name,
                  description: inputs.description,
                  questionIDs: selectedQuestions,
                });
                handleOnClose();
              }}
            >
              <h4>Done</h4>
            </button>
          </article>
        </section>
      </ModalLayout>
    </>
  );
};

export default CreateManualSet;
