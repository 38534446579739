import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { LineWave } from "react-loader-spinner";
import {
  DownloadInternalAudit,
  GetExportInternalAuditStatus,
} from "src/services/audit-management";

const ExportFile = ({ auditID }: { auditID: string }) => {
  const { data: exportStatus } = GetExportInternalAuditStatus(auditID);
  const downloadInternalAudit = DownloadInternalAudit(auditID);

  const onDownload = () => {
    downloadInternalAudit.mutate(
      {},
      {
        onSuccess: (data) => {
          const url = data;
          let a = document.createElement("a");
          a.href = url;
          a.download = "internal-audit.csv";
          a.target = "_blank";
          a.click();
        },
      }
    );
  };

  return (
    <article className="place-self-end">
      {exportStatus?.status === "parsing" ? (
        <span className="flex items-center gap-1">
          Exporting{" "}
          <LineWave
            visible={true}
            height="30"
            width="30"
            color="#4fa94d"
            ariaLabel="line-wave-loading"
            wrapperStyle={{}}
            wrapperClass=""
            firstLineColor=""
            middleLineColor=""
            lastLineColor=""
          />
        </span>
      ) : exportStatus?.status === "ready" ? (
        <button
          className="flex items-center gap-1 px-4 py-1 dark:bg-blue-600 dark:hover:bg-blue-600/30 duration-100 rounded-md"
          onClick={onDownload}
        >
          <FontAwesomeIcon icon={faDownload} className="w-4 h-4" />
          Download as File
        </button>
      ) : null}
    </article>
  );
};

export default ExportFile;
