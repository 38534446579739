import { motion } from "framer-motion";
import React, { useState } from "react";
import { validVariants } from "src/constants/general";
import { validateEmail } from "../../utils/general";

const RegularInput = ({
  label,
  keyName,
  inputs,
  setInputs,
  disabled,
  valid,
  setValid,
  required,
  textarea,
  placeholderText,
  singleEmail,
  multipleEmail,
}: {
  label: string;
  keyName: string;
  inputs: any;
  setInputs: (inputs: any) => void;
  disabled?: boolean;
  valid?: boolean;
  setValid?: (valid: boolean) => void;
  required?: boolean;
  textarea?: boolean;
  placeholderText?: string;
  singleEmail?: boolean;
  multipleEmail?: boolean;
}) => {
  const [clicked, setClicked] = useState<boolean>(false);
  const [emailValid, setEmailValid] = useState<boolean>(true);

  return (
    <article className="grid content-start gap-2 text-b2-reg">
      <header className="flex items-center gap-1 justify-self-start">
        <h4>{label}</h4>
        {required && <span className="text-h5 text-red-500">*</span>}
      </header>
      {textarea ? (
        <motion.textarea
          variants={validVariants}
          initial="hidden"
          animate={!valid && setValid ? "visible" : "hidden"}
          spellCheck="false"
          autoComplete="off"
          value={inputs[keyName]}
          disabled={disabled}
          placeholder="Please input"
          onClick={() => setClicked(false)}
          onBlur={() => setClicked(true)}
          onChange={(e) => {
            if (setValid) setValid(true);
            setInputs({
              ...inputs,
              [keyName]: e.target.value,
            });
          }}
          className={`px-4 py-2 w-full h-40 focus:outline-none text-b2-reg placeholder:text-b2-reg dark:placeholder:text-gray-500 dark:disabled:text-checkbox dark:bg-gray-900 dark:border-transparent ${
            required && clicked && inputs[keyName] === ""
              ? "border dark:border-reset"
              : "dark:focus:ring dark:focus:ring-offset-blue-500/50 dark:focus:ring-offset dark:focus:border dark:focus:border-blue-500"
          } rounded`}
        />
      ) : (
        <motion.input
          variants={validVariants}
          initial="hidden"
          animate={!valid && setValid ? "visible" : "hidden"}
          spellCheck="false"
          autoComplete="off"
          value={inputs[keyName]}
          disabled={disabled}
          placeholder={placeholderText ? placeholderText : "Please input"}
          onClick={() => setClicked(false)}
          onBlur={() => {
            setClicked(true);
            if (singleEmail) {
              const value = inputs[keyName].replace(" ", "");
              if (!validateEmail(value)) setEmailValid(false);
              else setEmailValid(true);
            } else if (multipleEmail) {
              const emails = inputs[keyName]
                .replace(" ", "")
                .split(inputs[keyName].includes(",") ? "," : ";");
              if (emails.some((email) => !validateEmail(email)))
                setEmailValid(false);
              else setEmailValid(true);
            }
          }}
          onChange={(e) => {
            if (setValid) setValid(true);
            setInputs({
              ...inputs,
              [keyName]: e.target.value,
            });
          }}
          type="input"
          className={`px-4 py-2 w-full h-9 focus:outline-none text-b2-reg dark:placeholder:text-gray-500 dark:disabled:text-checkbox dark:bg-gray-900 dark:border-transparent ${
            required && clicked && inputs[keyName] === ""
              ? "border dark:border-reset"
              : "dark:focus:ring dark:focus:ring-offset-blue-500/50 dark:focus:ring-offset dark:focus:border dark:focus:border-blue-500"
          } rounded`}
        />
      )}
      {!emailValid && (
        <span className="text-left dark:text-reset font-light tracking-wider">
          Email is not in correct format
        </span>
      )}
      {setValid && !valid && (
        <span className="text-left dark:text-reset font-light tracking-wider">
          {label} already exists
        </span>
      )}
    </article>
  );
};

export default RegularInput;
