/* eslint-disable react-hooks/exhaustive-deps */
import { faLink, faPlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useGeneralStore } from "src/stores/general";

const LogoInput = ({
  label,
  keyName,
  types,
  inputs,
  setInputs,
  required,
}: {
  label: string;
  keyName: string;
  types: string[];
  inputs: any;
  setInputs: any;
  required?: boolean;
}) => {
  const { error, setError } = useGeneralStore();

  const handleClearError = () => setError({ url: "", message: "" });

  useEffect(() => {
    handleClearError();
  }, []);

  return (
    <article className="grid content-start gap-2 text-b2-reg">
      <header className="flex items-center gap-1 justify-self-start">
        <h4>Upload {label}</h4>
        {required && <span className="text-h5 text-red-500">*</span>}
      </header>
      {error.message && (
        <p className="p-2 w-max break-words text-left dark:bg-inner border dark:border-error rounded-sm">
          {error.message}
        </p>
      )}
      <FileUploader
        handleChange={(file: any) => {
          handleClearError();
          if (types.includes("JSON")) {
            const reader = new FileReader();
            if (file) {
              reader.readAsText(file);
            }

            reader.onload = () => {
              setInputs({
                ...inputs,
                [keyName]: JSON.parse(String(reader.result)),
              });
            };
          } else
            setInputs({
              ...inputs,
              [keyName]: file,
            });
        }}
        name="file"
        types={types}
        children={
          <button className="flex flex-col flex-grow gap-3 items-center px-10 py-10 w-full h-full mx-auto dark:bg-gray-900 dark:hover:bg-gray-900/70 duration-100 outline-dashed outline-gray-500 rounded-sm">
            <FontAwesomeIcon icon={faPlus} />
            <p>Upload</p>
          </button>
        }
      />
      {inputs[keyName] && (
        <article className="flex items-center gap-2">
          <FontAwesomeIcon icon={faLink} />
          <h4>{inputs[keyName].name}</h4>
          <FontAwesomeIcon
            icon={faXmark}
            className="hidden group-hover:block red-button"
            onClick={() => {
              handleClearError();
              setInputs({
                ...inputs,
                [keyName]: "",
              });
            }}
          />
        </article>
      )}
    </article>
  );
};

export default LogoInput;
