import { faEyeSlash, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const PasswordInput = ({
  label,
  keyName,
  inputs,
  setInputs,
  showInputs,
  setShowInputs,
  disabled,
}: {
  label: string;
  keyName: string;
  inputs: any;
  setInputs: (inputs: any) => void;
  showInputs: any;
  setShowInputs: (showInputs: any) => void;
  disabled?: boolean;
}) => {
  return (
    <article className="grid content-start gap-1 text-b2-reg">
      <h4 className="justify-self-start">{label}</h4>
      <article
        className={`flex items-center py-2 ${
          showInputs[keyName] ? "pl-4" : "pl-1"
        } pr-4 dark:bg-gray-900`}
      >
        <input
          spellCheck="false"
          autoComplete="off"
          disabled={disabled}
          value={inputs[keyName]}
          onChange={(e) =>
            setInputs({
              ...inputs,
              [keyName]: e.target.value,
            })
          }
          type={showInputs[keyName] ? "input" : "password"}
          className="px-4 py-2 w-full h-9 focus:outline-none dark:placeholder:text-gray-500 dark:disabled:text-checkbox dark:bg-gray-900 dark:border-transparent dark:focus:ring dark:focus:ring-offset-blue-500/50 dark:focus:ring-offset dark:focus:border dark:focus:border-blue-500 rounded"
        />
        <button
          onClick={() =>
            setShowInputs({
              ...showInputs,
              [keyName]: !showInputs[keyName],
            })
          }
        >
          {showInputs[keyName] ? (
            <FontAwesomeIcon icon={faEyeSlash} />
          ) : (
            <FontAwesomeIcon icon={faEye} />
          )}
        </button>
      </article>
    </article>
  );
};

export default PasswordInput;
