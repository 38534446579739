import { useQuery, useMutation } from "react-query";
import { queryClient } from "src/App";
import { client } from "src/components/General/AxiosInterceptor";
import { apiVersion, pageSize } from "src/constants/general";
import { KeyStringVal } from "src/types/general";

const prefix = "grc/tprm/questions/global";

export const GetGlobalQuestions = (pageNumber?: number, tags?: string[]) =>
  useQuery<any, unknown, any, (string | number | string[] | undefined)[]>(
    ["get-global-questions", pageNumber, tags],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/questions`,
          pageNumber
            ? {
                page_size: pageSize,
                page_number: pageNumber,
                ...(tags?.length > 0 && { tags: tags }),
              }
            : null,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      keepPreviousData: false,
    }
  );

export const AddGlobalQuestion = () =>
  useMutation<any, unknown, any, string>(
    async ({
      question,
      question_type,
      signal,
    }: {
      question: string;
      question_type: string;
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/new`,
          { question, question_type },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-global-questions"]);
      },
    }
  );

export const AddGlobalQuestionSet = () =>
  useMutation<any, unknown, any, string>(
    async ({
      formData,
      signal,
    }: {
      formData: FormData;
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/new/from-file`,
          formData,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-global-questions"]);
      },
    }
  );

export const EditGlobalQuestion = (questionID: string) =>
  useMutation<any, unknown, any, string>(
    async ({
      question,
      response,
      question_type,
      signal,
    }: {
      question: string;
      response: string;
      question_type: string;
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.patch(
          `/api/${apiVersion}/${prefix}/${questionID}`,
          { question, response, question_type },
          { signal }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-global-questions"]);
      },
    }
  );

export const RemoveGlobalQuestion = () =>
  useMutation<any, unknown, any, string>(
    async ({
      questionIDs,
      signal,
    }: {
      questionIDs: string[];
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/questions/remove`,
          { question_ids: questionIDs },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-global-questions"]);
      },
    }
  );

export const ReorderGlobalQuestion = () =>
  useMutation<any, unknown, any, string>(
    async ({
      questionID,
      order_key,
      signal,
    }: {
      questionID: string;
      order_key: string;
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.patch(
          `/api/${apiVersion}/${prefix}/${questionID}/reorder`,
          { order_key },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-global-questions"]);
      },
    }
  );

export const GetGlobalQuestionTags = () =>
  useQuery<any, unknown, any, (string | number | KeyStringVal | undefined)[]>(
    ["get-global-questions-tags"],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${prefix}/questions/tags`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      keepPreviousData: false,
    }
  );

export const CreateTagForQuestions = () =>
  useMutation<any, unknown, any, string>(
    async ({ tagName, signal }: { tagName: string; signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/questions/tags`,
          { tag_name: tagName },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-global-questions-tags"]);
      },
    }
  );

export const DeleteTagFromQuestions = () =>
  useMutation<any, unknown, any, string>(
    async ({ tagID, signal }: { tagID: string; signal: AbortSignal }) => {
      try {
        const res = await client.delete(
          `/api/${apiVersion}/${prefix}/questions/tags/${tagID}`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-global-questions-tags"]);
      },
    }
  );

export const AssignTagToQuestion = (questionID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ tagID, signal }: { tagID: string; signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/questions/${questionID}/tags`,
          { tag_id: tagID },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-global-questions"]);
      },
    }
  );

export const RemoveTagFromQuestion = (questionID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ tagID, signal }: { tagID: string; signal: AbortSignal }) => {
      try {
        const res = await client.delete(
          `/api/${apiVersion}/${prefix}/questions/${questionID}/tags/${tagID}`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-global-questions"]);
      },
    }
  );

export const GetQuestionTypes = (pageNumber?: number, tags?: string[]) =>
  useQuery<any, unknown, any, (string | number | string[] | undefined)[]>(
    ["get-question-types", pageNumber, tags],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${prefix}/question_types`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      keepPreviousData: false,
    }
  );
