import React, { useState } from "react";
import ModalLayout from "src/layouts/ModalLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import RegularInput from "src/components/Input/RegularInput";
import { AddVendor } from "src/services/third-party-risk/vendors/vendors";
import {
  AddVendorLogo,
  GetVendorsAndPartners,
} from "../../../../services/third-party-risk/vendors/vendors";
import { KeyStringVal } from "../../../../types/general";
import UserInput from "../../../../components/Input/UserInput";
import LogoInput from "src/components/Input/LogoInput";
import { queryClient } from "src/App";
import CategoryInput from "../../../../components/Input/CategoryInput";

const NewVendor = () => {
  const [show, setShow] = useState<boolean>(false);
  const [inputs, setInputs] = useState<any>({
    name: "",
    inherent_risk: "critical",
    address: "",
    contact_name: "",
    contact_email: "",
    contact_phone: "",
    number_of_employees: "0",
    internal_email_list: "",
    business_user_name: "",
    department_head: "",
    location: "",
    department: "",
    vendor_type: "",
    vendor_manager: "",
    vendor_tier: "",
    rank_tier: "",
    website: "",
    duns_number: "",
    status: "",
    logo: "",
    is_publicly_traded: false,
    notes: "",
  });
  const [valid, setValid] = useState<boolean>(true);

  const { data: vendors } = GetVendorsAndPartners(1);
  const addVendor = AddVendor();
  const addVendorLogo = AddVendorLogo();

  const handleOnClose = () => setShow(false);
  const handleOnCloseConfirmation = () => addVendor.reset();

  return (
    <>
      <button
        className="place-self-end blue-button"
        onClick={() => {
          setShow(true);
          setInputs({
            name: "",
            inherent_risk: "critical",
            address: "",
            contact_name: "",
            contact_email: "",
            contact_phone: "",
            number_of_employees: "0",
            internal_email_list: "",
            business_user_name: "",
            department_head: "",
            location: "",
            department: "",
            vendor_type: "",
            vendor_manager: "",
            vendor_tier: "",
            rank_tier: "",
            website: "",
            duns_number: "",
            status: "",
            logo: "",
            is_publicly_traded: false,
            notes: "",
          });
        }}
      >
        <FontAwesomeIcon icon={faPlus} />
        <h4>Add Vendor</h4>
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="grid content-start gap-5 w-full h-full">
          <h3 className="flex items-center gap-2 text-t1-semi">Add Vendor</h3>
          <section className="flex items-center gap-10 w-full">
            <LogoInput
              label="Logo"
              keyName="logo"
              types={["jpeg", "png", "svg"]}
              inputs={inputs}
              setInputs={setInputs}
            />
            <section className="grid md:grid-cols-2 gap-5 w-full">
              <RegularInput
                label="Vendor"
                keyName="name"
                inputs={inputs}
                setInputs={setInputs}
                valid={valid}
                setValid={setValid}
                required
              />
              <RegularInput
                label="Vendor Type"
                keyName="vendor_type"
                inputs={inputs}
                setInputs={setInputs}
              />
              <RegularInput
                label="Contact Name"
                keyName="contact_name"
                inputs={inputs}
                setInputs={setInputs}
              />
              <RegularInput
                label="Contact Email"
                keyName="contact_email"
                inputs={inputs}
                setInputs={setInputs}
                singleEmail
              />
            </section>
          </section>
          <section className="grid md:grid-cols-2 gap-5">
            <RegularInput
              label="Contact Phone"
              keyName="contact_phone"
              inputs={inputs}
              setInputs={setInputs}
            />
            <RegularInput
              label="Address"
              keyName="address"
              inputs={inputs}
              setInputs={setInputs}
            />
            <RegularInput
              label="Number of Employees"
              keyName="number_of_employees"
              inputs={inputs}
              setInputs={setInputs}
            />
            <RegularInput
              label="Internal Email CC List"
              keyName="internal_email_list"
              inputs={inputs}
              setInputs={setInputs}
              multipleEmail
            />
            <RegularInput
              label="Name of Business User"
              keyName="business_user_name"
              inputs={inputs}
              setInputs={setInputs}
            />
            <RegularInput
              label="Head of Department"
              keyName="department_head"
              inputs={inputs}
              setInputs={setInputs}
            />
            <RegularInput
              label="Location"
              keyName="location"
              inputs={inputs}
              setInputs={setInputs}
            />
            <RegularInput
              label="Department"
              keyName="department"
              inputs={inputs}
              setInputs={setInputs}
            />
            <UserInput
              label="Vendor Manager"
              keyName="vendor_manager"
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
              vertical
            />
            <CategoryInput
              label="Status"
              keyName="status"
              list={["active", "inactive"]}
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
              vertical
            />
            <CategoryInput
              label="Inherent Risk"
              keyName="inherent_risk"
              list={["critical", "high", "medium", "low"]}
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
              vertical
            />
            <CategoryInput
              label="Vendor Tier"
              keyName="vendor_tier"
              list={["critical", "high", "medium", "low"]}
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
              vertical
            />
            <CategoryInput
              label="Rank Tier"
              keyName="rank_tier"
              list={["critical", "high", "medium", "low"]}
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
              vertical
            />
          </section>
          <article className="flex items-center gap-2 text-b2-reg">
            <input
              type="checkbox"
              checked={inputs.is_publicly_traded}
              className="form-checkbox w-4 h-4 border-0 dark:focus:ring-0 dark:text-blue-600 dark:focus:border-blue-600 focus:ring dark:focus:ring-offset-0 dark:focus:ring-blue-600 focus:ring-opacity-50"
              onChange={() => {
                setInputs({
                  ...inputs,
                  is_publicly_traded: !inputs.is_publicly_traded,
                });
              }}
            />
            <label>Publicly traded</label>
          </article>
          <RegularInput
            label="Notes"
            keyName="notes"
            inputs={inputs}
            setInputs={setInputs}
            textarea
          />
          <article className="flex items-center place-content-end gap-5">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={inputs.name === "" || inputs.inherent_risk === ""}
              className="blue-button"
              onClick={() => {
                if (
                  vendors?.data.some(
                    (vendor: KeyStringVal) =>
                      vendor.name.toLowerCase().trim() ===
                      inputs.name.toLowerCase().trim()
                  )
                )
                  setValid(false);
                else {
                  addVendor.mutate(
                    {
                      info: {
                        name: inputs.name,
                        inherent_risk: inputs.inherent_risk,
                        ...(inputs.address !== "" && {
                          address: inputs.address,
                        }),
                        ...(inputs.contact_name !== "" && {
                          contact_name: inputs.contact_name,
                        }),
                        ...(inputs.contact_email !== "" && {
                          contact_email: inputs.contact_email,
                        }),
                        ...(inputs.contact_phone !== "" && {
                          contact_phone: inputs.contact_phone,
                        }),
                        ...(inputs.internal_email_list !== "" && {
                          internal_email_list: inputs.internal_email_list,
                        }),
                        ...(inputs.business_user_name !== "" && {
                          business_user_name: inputs.business_user_name,
                        }),
                        ...(inputs.department_head !== "" && {
                          department_head: inputs.department_head,
                        }),
                        ...(inputs.location !== "" && {
                          location: inputs.location,
                        }),
                        ...(inputs.department !== "" && {
                          department: inputs.department,
                        }),
                        creation_date: Date.now() * 1000,
                        ...(inputs.vendor_type !== "" && {
                          vendor_type: inputs.vendor_type,
                        }),
                        ...(inputs.vendor_manager !== "" && {
                          vendor_manager: inputs.vendor_manager,
                        }),
                        ...(inputs.vendor_tier !== "" && {
                          vendor_tier: inputs.vendor_tier,
                        }),
                        ...(inputs.rank_tier !== "" && {
                          rank_tier: inputs.rank_tier,
                        }),
                        ...(inputs.website !== "" && {
                          website: inputs.website,
                        }),
                        ...(inputs.duns_number !== "" && {
                          duns_number: inputs.duns_number,
                        }),
                        number_of_employees: inputs.number_of_employees,
                        ...(inputs.status !== "" && { status: inputs.status }),
                        is_publicly_traded: inputs.is_publicly_traded,
                        ...(inputs.notes !== "" && { notes: inputs.notes }),
                      },
                    },
                    {
                      onSuccess: (data) => {
                        queryClient.invalidateQueries(["get-vendors"]);

                        if (inputs.logo) {
                          const vendorID = data?.slice(
                            data?.lastIndexOf(": ") + 2
                          );
                          const formData = new FormData();
                          formData.append("file", inputs.logo);
                          addVendorLogo.mutate({
                            vendorID: vendorID,
                            formData: formData,
                          });
                        }
                      },
                    }
                  );

                  handleOnClose();
                }
              }}
            >
              <h4>Done</h4>
            </button>
          </article>
        </section>
      </ModalLayout>
      <ModalLayout
        showModal={addVendor.data !== undefined}
        onClose={handleOnCloseConfirmation}
      >
        <section className="grid content-start gap-5 py-4 h-full mx-auto">
          <article className="flex items-center gap-2">
            <img
              src="/general/checkmark.svg"
              alt="checkmark"
              className="w-6 h-6"
            />
            <h3 className="text-lg">New Vendor has been created!</h3>
          </article>
        </section>
      </ModalLayout>
    </>
  );
};

export default NewVendor;
