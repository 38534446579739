import {
  faChevronDown,
  faChevronRight,
  faMagnifyingGlass,
  faMinus,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Disclosure, Popover, Transition } from "@headlessui/react";
import React, { Fragment, useState } from "react";
import { useGRCStore } from "src/stores/grc";
import { KeyStringVal } from "src/types/general";
import Source from "./Source";
import {
  AddQAPair,
  EditAnswer,
  GetQuestionBanks,
} from "src/services/questionnaire-rfp";
import { ResubmitQuestion } from "../../../../../services/questionnaire-rfp";
import DeleteResponse from "./DeleterResponse";

const Response = ({
  assessmentID,
  questionIndex,
  questionRef,
  question,
  selectedQuestions,
  setSelectedQuestions,
}: {
  assessmentID: string;
  questionIndex: number;
  questionRef: any;
  question: any;
  selectedQuestions: any;
  setSelectedQuestions: any;
}) => {
  const questionID = question.question_id;
  const sourceType = sessionStorage.source_type || "";

  const { GRCQuestionIDNotif, setGRCQuestionIDNotif } = useGRCStore();

  const [editedAnswer, setEditedAnswer] = useState<string>(question.answer);

  const { data: questionBanks } = GetQuestionBanks(1);
  const addQAPair = AddQAPair();
  const editAnswer = EditAnswer(assessmentID);
  const resubmitQuestion = ResubmitQuestion(
    assessmentID,
    questionID,
    sourceType
  );

  const added = selectedQuestions.some(
    (curQuestion: KeyStringVal) => curQuestion.question_id === questionID
  );

  const handleDiscardEditedAnswer = () => setEditedAnswer(question.answer);

  return (
    <li
      key={questionIndex}
      ref={(el) => {
        if (questionRef && questionRef.current)
          questionRef.current[questionIndex] = el;
      }}
      className="flex items-start gap-2 w-full"
    >
      <input
        type="checkbox"
        checked={added}
        className="form-checkbox mt-1 w-4 h-4 border-0 dark:focus:ring-0 dark:text-blue-600 dark:focus:border-blue-600 focus:ring dark:focus:ring-offset-0 dark:focus:ring-blue-600 focus:ring-opacity-50"
        onChange={() => {
          if (added)
            setSelectedQuestions(
              selectedQuestions.filter(
                (curQuestion) => curQuestion.question_id !== questionID
              )
            );
          else setSelectedQuestions([...selectedQuestions, question]);
        }}
      />
      <Disclosure
        key={questionIndex}
        defaultOpen={questionID === GRCQuestionIDNotif}
      >
        {({ open }) => {
          return (
            <section className="grid gap-2 w-full">
              <header className="flex items-start gap-2 text-base w-4/5">
                <h4 className="text-left">Q: {question.question}</h4>
                <Disclosure.Button
                  onClick={() => {
                    if (GRCQuestionIDNotif !== "") setGRCQuestionIDNotif("");
                  }}
                >
                  <FontAwesomeIcon
                    icon={open ? faChevronDown : faChevronRight}
                  />
                </Disclosure.Button>
                <DeleteResponse
                  assessmentID={assessmentID}
                  questionID={questionID}
                />
              </header>
              <Transition
                show={questionID === GRCQuestionIDNotif || open}
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                <Disclosure.Panel>
                  <section className="grid gap-3 p-4 text-b1-reg dark:bg-gray-700 rounded">
                    <article className="flex items-start gap-2 w-full">
                      A:{" "}
                      <textarea
                        value={editedAnswer}
                        onChange={(e) => setEditedAnswer(e.target.value)}
                        className="px-4 py-2 w-full h-32 focus:outline-none placeholder:text-b2-reg dark:placeholder:text-gray-500 dark:disabled:text-checkbox dark:bg-gray-900 dark:border-transparent dark:focus:ring dark:focus:ring-offset-blue-500/50 dark:focus:ring-offset dark:focus:border dark:focus:border-blue-500 resize-none rounded"
                      />
                    </article>

                    {question.answer !== editedAnswer && (
                      <article className="flex items-center justify-self-end gap-2">
                        <button
                          className="red-button"
                          onClick={handleDiscardEditedAnswer}
                        >
                          Discard
                        </button>
                        <button
                          className="blue-button"
                          onClick={() => {
                            editAnswer.mutate({
                              questionID: questionID,
                              answer: editedAnswer,
                            });
                            handleDiscardEditedAnswer();
                          }}
                        >
                          Save
                        </button>
                      </article>
                    )}
                    {question.context?.length > 0 && (
                      <section className="grid gap-2">
                        <Disclosure>
                          {({ open }) => {
                            return (
                              <section className="grid gap-2 text-b1-reg">
                                <Disclosure.Button className="flex items-center gap-2 w-max">
                                  <h4>Sources</h4>
                                  <FontAwesomeIcon
                                    icon={open ? faChevronDown : faChevronRight}
                                  />
                                </Disclosure.Button>
                                <Transition
                                  show={open}
                                  enter="transition duration-100 ease-out"
                                  enterFrom="transform scale-95 opacity-0"
                                  enterTo="transform scale-100 opacity-100"
                                  leave="transition duration-75 ease-out"
                                  leaveFrom="transform scale-100 opacity-100"
                                  leaveTo="transform scale-95 opacity-0"
                                >
                                  <Disclosure.Panel>
                                    <section className="flex flex-wrap items-center gap-5">
                                      {question.context.map(
                                        (
                                          source: KeyStringVal,
                                          sourceIndex: number
                                        ) => {
                                          return (
                                            <Source
                                              key={sourceIndex}
                                              sourceIndex={sourceIndex}
                                              source={source}
                                            />
                                          );
                                        }
                                      )}
                                    </section>
                                  </Disclosure.Panel>
                                </Transition>
                              </section>
                            );
                          }}
                        </Disclosure>
                      </section>
                    )}
                    {question.mapped_questions?.from_assessments?.length >
                      0 && (
                      <section className="grid gap-2">
                        <Disclosure>
                          {({ open }) => {
                            return (
                              <section className="grid gap-2">
                                <Disclosure.Button className="flex items-center gap-2 w-max">
                                  <h4>From Assessments</h4>
                                  <FontAwesomeIcon
                                    icon={open ? faChevronDown : faChevronRight}
                                  />
                                </Disclosure.Button>
                                <Transition
                                  show={open}
                                  enter="transition duration-100 ease-out"
                                  enterFrom="transform scale-95 opacity-0"
                                  enterTo="transform scale-100 opacity-100"
                                  leave="transition duration-75 ease-out"
                                  leaveFrom="transform scale-100 opacity-100"
                                  leaveTo="transform scale-95 opacity-0"
                                >
                                  <Disclosure.Panel>
                                    <section className="flex flex-wrap items-center gap-5">
                                      {question.mapped_questions?.from_assessments?.map(
                                        (
                                          source: KeyStringVal,
                                          sourceIndex: number
                                        ) => {
                                          return (
                                            <Source
                                              key={sourceIndex}
                                              sourceIndex={sourceIndex}
                                              source={source}
                                              questionID={questionID}
                                              selectedQuestions={
                                                selectedQuestions
                                              }
                                              setSelectedQuestions={
                                                setSelectedQuestions
                                              }
                                            />
                                          );
                                        }
                                      )}
                                    </section>
                                  </Disclosure.Panel>
                                </Transition>
                              </section>
                            );
                          }}
                        </Disclosure>
                      </section>
                    )}
                    {question.mapped_questions?.from_question_banks?.length >
                      0 && (
                      <section className="grid gap-2">
                        <Disclosure>
                          {({ open }) => {
                            return (
                              <section className="grid gap-2">
                                <Disclosure.Button className="flex items-center gap-2 w-max">
                                  <h4>From Question Banks</h4>
                                  <FontAwesomeIcon
                                    icon={open ? faChevronDown : faChevronRight}
                                  />
                                </Disclosure.Button>
                                <Transition
                                  show={open}
                                  enter="transition duration-100 ease-out"
                                  enterFrom="transform scale-95 opacity-0"
                                  enterTo="transform scale-100 opacity-100"
                                  leave="transition duration-75 ease-out"
                                  leaveFrom="transform scale-100 opacity-100"
                                  leaveTo="transform scale-95 opacity-0"
                                >
                                  <Disclosure.Panel>
                                    <section className="flex flex-wrap items-center gap-5">
                                      {question.mapped_questions?.from_question_banks?.map(
                                        (
                                          source: KeyStringVal,
                                          sourceIndex: number
                                        ) => {
                                          return (
                                            <Source
                                              key={sourceIndex}
                                              sourceIndex={sourceIndex}
                                              source={source}
                                              questionID={questionID}
                                              selectedQuestions={
                                                selectedQuestions
                                              }
                                              setSelectedQuestions={
                                                setSelectedQuestions
                                              }
                                            />
                                          );
                                        }
                                      )}
                                    </section>
                                  </Disclosure.Panel>
                                </Transition>
                              </section>
                            );
                          }}
                        </Disclosure>
                      </section>
                    )}
                  </section>
                </Disclosure.Panel>
              </Transition>
            </section>
          );
        }}
      </Disclosure>
    </li>
  );
};

export default Response;
