export const businessContinuityTabs = [
  "standard operating procedures",
  "business impact analysis",
];

export const riskComplianceTabs = [
  "overview",
  "frameworks",
  "circulars",
  "policies",
];

export const riskIntelligenceTabs = [
  "regulatory updates",
  "enforcement actions",
];

export const thirdPartyRiskTabs = [
  "vendors",
  "assessments",
  // "risk report",
];

export const targetDoc = {
  document_path: "",
  document_id: "",
};

export const versionTimelineColors = [
  {
    text: "dark:text-note",
    bg: "dark:bg-note",
    ring: "dark:ring-note",
    border: "dark:border-note",
  },
  {
    text: "dark:text-event",
    bg: "dark:bg-event",
    ring: "dark:ring-event",
    border: "dark:border-event",
  },
  {
    text: "dark:text-reset",
    bg: "dark:bg-reset",
    ring: "dark:ring-reset",
    border: "dark:border-reset",
  },
  {
    text: "dark:text-purple-500",
    bg: "dark:bg-purple-500",
    ring: "dark:ring-purple-500",
    border: "dark:border-purple-500",
  },
  {
    text: "dark:text-no",
    bg: "dark:bg-no",
    ring: "dark:ring-no",
    border: "dark:border-no",
  },
];

export const driftColors = {
  insert: "bg-no/50 hover:bg-no/80 duration-100",
  delete: "bg-reset/50 hover:bg-reset/80 duration-100",
  replace: "bg-note/50 hover:bg-note/80 duration-100",
  equal: "bg-filter/50 hover:bg-filter/80 duration-100",
};

export const driftTooltipColors = {
  insert: "bg-no border border-no",
  delete: "bg-reset border border-reset",
  replace: "text-black bg-note border border-note",
  equal: "bg-filter border border-filter",
};

export const frameworkSortingTypes = ["framework_name", "regulatory_date"];

export const thirdPartySortingTypes = ["created_at"];

export const agreementContractReviewSortingTypes = [
  "agreement_name",
  "agreement_date",
];

export const redliningSortingTypes = ["created_at"];
