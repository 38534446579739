import { useQuery, useMutation } from "react-query";
import { queryClient } from "src/App";
import { client } from "src/components/General/AxiosInterceptor";
import { apiVersion, pageSize } from "src/constants/general";
import { KeyStringVal } from "src/types/general";

const prefix = "grc/tprm/third-parties";
const vendorPortalPrefix = "grc/vendors/portal";

export const GetVendorsAndPartners = (
  pageNumber: number,
  tags?: string[] | undefined,
  groups?: string[]
) =>
  useQuery<any, unknown, any, (string | number | string[] | undefined)[]>(
    ["get-vendors", pageNumber, tags, groups],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/all`,
          {
            page_number: pageNumber,
            page_size: pageSize,
            ...(tags?.length > 0 && { tags: tags }),
            ...(groups?.length > 0 && { group_name_list: groups }),
          },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      keepPreviousData: false,
    }
  );

export const AddVendor = () =>
  useMutation<any, unknown, any, string>(
    async ({ info, signal }: { info: any; signal: AbortSignal }) => {
      try {
        const res = await client.post(`/api/${apiVersion}/${prefix}`, info, {
          signal,
        });
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    }
  );

export const AddVendorLogo = () =>
  useMutation<any, unknown, any, string>(
    async ({
      vendorID,
      formData,
      signal,
    }: {
      vendorID: string;
      formData: FormData;
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/${vendorID}/logo`,
          formData,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-vendors"]);
      },
    }
  );

export const EditVendor = (vendorID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ info, signal }: { info: any; signal: AbortSignal }) => {
      try {
        const res = await client.patch(
          `/api/${apiVersion}/${prefix}/${vendorID}`,
          info,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    }
  );

export const EditVendorLogo = () =>
  useMutation<any, unknown, any, string>(
    async ({
      vendorID,
      formData,
      signal,
    }: {
      vendorID: string;
      formData: FormData;
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.patch(
          `/api/${apiVersion}/${prefix}/${vendorID}/logo`,
          formData,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-vendors"]);
      },
    }
  );

export const RemoveVendor = (vendorID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ signal }: { signal: AbortSignal }) => {
      try {
        const res = await client.delete(
          `/api/${apiVersion}/${prefix}/${vendorID}`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-vendors"]);
        queryClient.invalidateQueries(["get-assessment-cycle-vendor-list"]);
      },
    }
  );

export const ActivateVendor = (vendorID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ signal }: { signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/${vendorID}/activate`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-vendors"]);
        queryClient.invalidateQueries(["get-vendor-metadata"]);
        queryClient.invalidateQueries(["get-assessment-cycle-vendor-list"]);
      },
    }
  );

export const DeactivateVendor = (vendorID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ signal }: { signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/${vendorID}/deactivate`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-vendors"]);
        queryClient.invalidateQueries(["get-vendor-metadata"]);
        queryClient.invalidateQueries(["get-assessment-cycle-vendor-list"]);
      },
    }
  );

export const GetVendorMetadata = (vendorID: string, assessmentID?: string) =>
  useQuery<any, unknown, any, (string | undefined)[]>(
    ["get-vendor-metadata", vendorID, assessmentID],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${prefix}/${vendorID}${
            assessmentID ? `?assessment_id=${assessmentID}` : ""
          }`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: vendorID !== "",
      keepPreviousData: false,
    }
  );

export const SearchVendor = () =>
  useMutation<any, unknown, any, string[]>(
    async ({ vendor, signal }: { vendor: string; signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/searches`,
          {
            third_party_name: vendor,
          },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    }
  );

export const GetVendorAuditReports = (
  vendorID: string,
  assessmentID: string,
  order: KeyStringVal,
  pageNumber: number,
  category?: string
) =>
  useQuery<any, unknown, any, (string | number | KeyStringVal | undefined)[]>(
    [
      "get-vendor-audit-reports",
      vendorID,
      assessmentID,
      order,
      pageNumber,
      category,
    ],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/grc/tprm/assessments`,
          {
            third_party_id: vendorID,
            assessment_id: assessmentID,
            ...(order.order_by !== "" && { order: order }),
            page_number: pageNumber,
            page_size: pageSize,
            ...(category && { category: category }),
          },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: vendorID !== "",
      keepPreviousData: false,
    }
  );

export const GetVendorResponses = (
  vendorID: string,
  assessmentID: string | undefined,
  documentID: string,
  pageNumber: number,
  filter: string
) =>
  useQuery<any, unknown, any, (string | number | KeyStringVal | undefined)[]>(
    [
      "get-vendor-responses",
      vendorID,
      assessmentID,
      documentID,
      pageNumber,
      filter,
    ],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/${vendorID}/responses/all${
            assessmentID ? `?assessment_id=${assessmentID}` : ""
          }`,
          {
            document_id: documentID,
            pager: {
              page_number: pageNumber,
              page_size: pageSize,
            },
            filter: filter,
          },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: vendorID !== "",
      keepPreviousData: false,
    }
  );

export const SubmitVendorResponses = (
  vendorID: string,
  assessmentID: string | undefined
) =>
  useMutation<any, unknown, any, string[]>(
    async ({
      formData,
      signal,
    }: {
      formData: FormData;
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/${vendorID}/responses${
            assessmentID ? `?assessment_id=${assessmentID}` : ""
          }`,
          formData,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => queryClient.invalidateQueries(["get-vendor-responses"]),
    }
  );

export const AddVendorResponse = (
  vendorID: string,
  assessmentID: string | undefined
) =>
  useMutation<any, unknown, any, string>(
    async ({
      question,
      response,
      signal,
    }: {
      question: string;
      response: string;
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/${vendorID}/responses/new${
            assessmentID ? `?assessment_id=${assessmentID}` : ""
          }`,
          { question, response },
          { signal }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-vendor-responses"]);
      },
    }
  );

export const EditVendorResponse = (
  vendorID: string,
  assessmentID: string | undefined
) =>
  useMutation<any, unknown, any, string>(
    async ({
      questionID,
      question,
      response,
      signal,
    }: {
      questionID: string;
      question: string;
      response: string;
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.patch(
          `/api/${apiVersion}/${prefix}/${vendorID}/responses/${questionID}${
            assessmentID ? `?assessment_id=${assessmentID}` : ""
          }`,
          { question, response },
          { signal }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-vendor-responses"]);
      },
    }
  );

export const RemoveVendorResponse = (
  vendorID: string,
  assessmentID: string | undefined,
  questionID: string
) =>
  useMutation<any, unknown, any, string>(
    async ({ signal }: { signal: AbortSignal }) => {
      try {
        const res = await client.delete(
          `/api/${apiVersion}/${prefix}/${vendorID}/responses/${questionID}${
            assessmentID ? `?assessment_id=${assessmentID}` : ""
          }`,
          { signal }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-vendor-responses"]);
      },
    }
  );

export const ExportVendorResponses = (
  vendorID: string,
  assessmentID: string | undefined
) =>
  useMutation<any, unknown, any, string>(
    async ({ signal }: { signal: AbortSignal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${prefix}/${vendorID}/questions/as-file${
            assessmentID ? `?assessment_id=${assessmentID}` : ""
          }`,
          { signal }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    }
  );

export const GetVendorTags = () =>
  useQuery<any, unknown, any, (string | number | KeyStringVal | undefined)[]>(
    ["get-vendor-tags"],
    async ({ signal }) => {
      try {
        const res = await client.get(`/api/${apiVersion}/${prefix}/tags`, {
          signal,
        });
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      keepPreviousData: false,
    }
  );

export const CreateTagForVendors = () =>
  useMutation<any, unknown, any, string>(
    async ({ tagName, signal }: { tagName: string; signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/tags`,
          { tag_name: tagName },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-vendor-tags"]);
      },
    }
  );

export const DeleteTagFromVendors = () =>
  useMutation<any, unknown, any, string>(
    async ({ tagID, signal }: { tagID: string; signal: AbortSignal }) => {
      try {
        const res = await client.delete(
          `/api/${apiVersion}/${prefix}/tags/${tagID}`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-vendor-tags"]);
      },
    }
  );

export const GetTagsForVendor = (vendorID: string) =>
  useQuery<any, unknown, any, (string | number | KeyStringVal | undefined)[]>(
    ["get-tags-for-vendor", vendorID],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${prefix}/${vendorID}/tags`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: vendorID !== "",
      keepPreviousData: false,
    }
  );

export const AssignTagToVendor = (vendorID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ tagName, signal }: { tagName: string; signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/${vendorID}/tag`,
          { tag_name: tagName },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-vendors"]);
        queryClient.invalidateQueries(["get-vendor-metadata"]);
        queryClient.invalidateQueries(["get-tags-for-vendor"]);
      },
    }
  );

export const RemoveTagFromVendor = (vendorID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ tagName, signal }: { tagName: string; signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/${vendorID}/untag`,
          { tag_name: tagName },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-vendors"]);
        queryClient.invalidateQueries(["get-vendor-metadata"]);
        queryClient.invalidateQueries(["get-tags-for-vendor"]);
      },
    }
  );

export const UploadDocuments = (vendorID: string, assessmentID: string) =>
  useMutation<any, unknown, any, string>(
    async ({
      formData,
      signal,
    }: {
      formData: FormData;
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/${vendorID}/upload-documents${
            assessmentID ? `?assessment_id=${assessmentID}` : ""
          }`,
          formData,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    }
  );

export const GetDocumentTypes = () =>
  useQuery<any, unknown, any, (string | number | KeyStringVal | undefined)[]>(
    ["get-document-types"],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${prefix}/document-types`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      keepPreviousData: false,
    }
  );

export const GetVendorDocuments = (vendorID: string, assessmentID: string) =>
  useQuery<any, unknown, any, (string | number | KeyStringVal | undefined)[]>(
    ["get-vendor-documents", vendorID, assessmentID],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${prefix}/${vendorID}/documents${
            assessmentID ? `?assessment_id=${assessmentID}` : ""
          }`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: vendorID !== "",
      keepPreviousData: false,
    }
  );

export const RemoveDocument = (
  vendorID: string,
  assessmentID: string,
  documentID: string
) =>
  useMutation<any, unknown, any, string>(
    async ({ signal }: { signal: AbortSignal }) => {
      try {
        const res = await client.delete(
          `/api/${apiVersion}/${prefix}/${vendorID}/documents/${documentID}${
            assessmentID ? `?assessment_id=${assessmentID}` : ""
          }`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-vendor-documents"]);
      },
    }
  );

export const PostQuestions = (
  vendorID: string,
  assessmentID: string | undefined
) =>
  useMutation<any, unknown, any, string>(
    async ({
      questions,
      signal,
    }: {
      questions: string[];
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/${vendorID}/documents/question${
            assessmentID ? `?assessment_id=${assessmentID}` : ""
          }`,
          { questions },
          { signal }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-document-qna-list"]);
      },
    }
  );

export const RemoveResponse = (
  vendorID: string,
  assessmentID: string | undefined,
  questionID: string
) =>
  useMutation<any, unknown, any, string>(
    async ({ signal }: { signal: AbortSignal }) => {
      try {
        const res = await client.delete(
          `/api/${apiVersion}/${prefix}/${vendorID}/qna/delete-response?question_id=${questionID}${
            assessmentID ? `&assessment_id=${assessmentID}` : ""
          }`,
          { signal }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-document-qna-list"]);
      },
    }
  );

export const ResubmitQuestion = (
  vendorID: string,
  assessmentID: string | undefined
) =>
  useMutation<any, unknown, any, string>(
    async ({
      questionID,
      signal,
    }: {
      questionID: string;
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/${vendorID}/documents/resubmit${
            assessmentID ? `?assessment_id=${assessmentID}` : ""
          }`,
          { question_id: questionID },
          { signal }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-document-qna-list"]);
      },
    }
  );

export const GetDocumentQnAList = (
  vendorID: string,
  assessmentID: string,
  pageNumber: number,
  query: string
) =>
  useQuery<any, unknown, any, (string | number)[]>(
    ["get-document-qna-list", assessmentID, pageNumber],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/${vendorID}/documents/question-history${
            assessmentID ? `?assessment_id=${assessmentID}` : ""
          }`,
          {
            page_number: pageNumber,
            page_size: pageSize,
            query: query,
          },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      keepPreviousData: false,
    }
  );

export const GetVendorQuestions = (
  vendorID: string,
  assessmentID: string | undefined
) =>
  useQuery<any, unknown, any, (string | number | KeyStringVal | undefined)[]>(
    ["get-vendor-questions", vendorID, assessmentID],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/${vendorID}/documents/question/all${
            assessmentID ? `?assessment_id=${assessmentID}` : ""
          }`,
          {
            page_number: 1,
            page_size: 10000,
          },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: vendorID !== "",
      keepPreviousData: false,
    }
  );

export const GetVendorDrafts = (vendorID: string, assessmentID: string) =>
  useQuery<any, unknown, any, (string | (string | null)[])[]>(
    ["get-vendor-drafts", vendorID],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${prefix}/${vendorID}/documents/question/drafts${
            assessmentID ? `?assessment_id=${assessmentID}` : ""
          }`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      keepPreviousData: false,
      refetchInterval: 60000,
    }
  );

export const GetDraftStatus = (vendorID: string, draftID: string) =>
  useQuery<any, unknown, any, (string | (string | null)[])[]>(
    ["get-draft-status", vendorID, draftID],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${prefix}/${vendorID}/documents/question/drafts/${draftID}/status`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: vendorID !== "" && !["1", "2"].includes(draftID),
      keepPreviousData: false,
      refetchInterval: 60000,
    }
  );

export const MarkDraftAsComplete = (vendorID: string) =>
  useMutation<any, unknown, any, string>(
    async ({
      draftID,
      questions,
      signal,
    }: {
      draftID: string;
      questions: string[];
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/${vendorID}/documents/question/drafts/${draftID}`,
          {
            questions: questions,
          },
          { signal }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-vendor-drafts", vendorID]);
      },
    }
  );

export const SubmitQuestionnaire = (vendorID: string, assessmentID: string) =>
  useMutation<any, unknown, any, string>(
    async ({
      formData,
      signal,
    }: {
      formData: FormData;
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/${vendorID}/documents/question/drafts/new${
            assessmentID ? `?assessment_id=${assessmentID}` : ""
          }`,
          formData,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-vendor-drafts"]);
        queryClient.invalidateQueries(["get-draft-status"]);
      },
    }
  );

export const GetQnANotes = (
  vendorID: string,
  assessmentID: string,
  questionID: string
) =>
  useQuery<any, unknown, any, (string | (string | null)[])[]>(
    ["get-qna-notes", vendorID, assessmentID, questionID],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${prefix}/${vendorID}/${questionID}/get-notes${
            assessmentID ? `?assessment_id=${assessmentID}` : ""
          }`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      keepPreviousData: false,
    }
  );

export const PostQnANote = (
  vendorID: string,
  assessmentID: string,
  questionID: string
) =>
  useMutation<any, unknown, any, string>(
    async ({
      user,
      notes,
      signal,
    }: {
      user: string;
      notes: string;
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/${vendorID}/${questionID}/assign-notes${
            assessmentID ? `?assessment_id=${assessmentID}` : ""
          }`,
          { assigned_to_user: user, notes_txt: notes },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-qna-notes"]);
      },
    }
  );

export const GetVendorResponseNotes = (
  vendorID: string,
  assessmentID: string,
  questionID: string
) =>
  useQuery<any, unknown, any, (string | (string | null)[])[]>(
    ["get-vendor-response-notes", vendorID, assessmentID, questionID],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${prefix}/${vendorID}/responses/${questionID}/get-notes${
            assessmentID ? `?assessment_id=${assessmentID}` : ""
          }`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      keepPreviousData: false,
    }
  );

export const PostVendorResponseNote = (
  vendorID: string,
  assessmentID: string,
  questionID: string
) =>
  useMutation<any, unknown, any, string>(
    async ({
      user,
      notes,
      signal,
    }: {
      user: string;
      notes: string;
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.patch(
          `/api/${apiVersion}/${prefix}/${vendorID}/responses/${questionID}/add-notes${
            assessmentID ? `?assessment_id=${assessmentID}` : ""
          }`,
          { assigned_to_user: user, notes_txt: notes },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-vendor-responses"]);
      },
    }
  );

export const ExportResponses = (vendorID: string, assessmentID: string) =>
  useMutation<any, unknown, any, string>(
    async ({
      includesCitations,
      questions,
      signal,
    }: {
      includesCitations: boolean;
      questions: any;
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/${vendorID}/documents/question-history/export${
            assessmentID ? `?assessment_id=${assessmentID}` : ""
          }`,
          { include_citations: includesCitations, questions: questions },
          { signal }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "get-export-responses-status",
          vendorID,
          assessmentID,
        ]);
      },
    }
  );

export const GetExportResponsesStatus = (
  vendorID: string,
  assessmentID: string
) =>
  useQuery<any, unknown, any, (string | number)[]>(
    ["get-export-responses-status", vendorID, assessmentID],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${prefix}/${vendorID}/documents/question-history/export/status${
            assessmentID ? `?assessment_id=${assessmentID}` : ""
          }`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: assessmentID !== "",
      keepPreviousData: false,
      refetchInterval: 30000,
    }
  );

export const DownloadResponses = (vendorID: string, assessmentID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ signal }: { signal: AbortSignal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${prefix}/${vendorID}/documents/question-history/export${
            assessmentID ? `?assessment_id=${assessmentID}` : ""
          }`,
          { signal }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "get-export-responses-status",
          vendorID,
          assessmentID,
        ]);
      },
    }
  );

export const GetQuestionBanks = (pageNumber: number) =>
  useQuery<any, unknown, any, (string | number | undefined)[]>(
    ["get-question-banks", pageNumber],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/question-banks/all`,
          { page_size: pageSize, page_number: pageNumber },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    }
  );

export const AddQAPair = () =>
  useMutation<any, unknown, any, string>(
    async ({
      questionSetID,
      question,
      answer,
      signal,
    }: {
      questionSetID: string;
      question: string;
      answer: string;
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/question-sets/${questionSetID}/questions/add`,
          { to_add: [{ question: question, answer: answer }] },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-custom-question-sets"]);
      },
    }
  );

export const EditAnswer = (vendorID: string, assessmentID: string) =>
  useMutation<any, unknown, any, string>(
    async ({
      questionID,
      answer,
      signal,
    }: {
      questionID: string;
      answer: string;
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.patch(
          `/api/${apiVersion}/${prefix}/${vendorID}/documents/question-history/${questionID}${
            assessmentID ? `?assessment_id=${assessmentID}` : ""
          }`,
          { answer: answer },
          { signal }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-document-qna-list"]);
      },
    }
  );

export const SearchVendorGroup = () =>
  useMutation<any, unknown, any, string[]>(
    async ({
      groupName,
      signal,
    }: {
      groupName: string;
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/search_group`,
          { group_name: groupName },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    }
  );

export const SendEmailToVendor = (vendorID: string, assessmentID?: string) =>
  useMutation<any, unknown, any, string>(
    async ({ signal }: { signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/${vendorID}/send-email${
            assessmentID ? `?assessment_id=${assessmentID}` : ""
          }`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    }
  );

export const RetrieveJWTForVendor = (vendorID: string, assessmentID?: string) =>
  useMutation<any, unknown, any, string>(
    async ({
      formData,
      signal,
    }: {
      formData: FormData;
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/third_party_access_token?third_party_id=${vendorID}${
            assessmentID ? `&assessment_id=${assessmentID}` : ""
          }`,
          formData,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    }
  );

export const RetrieveVendorQuestions = (pageNumber: number) =>
  useMutation<any, unknown, any, string>(
    async ({ signal }: { signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${vendorPortalPrefix}/questions`,
          { page_number: pageNumber, page_size: pageSize },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    }
  );

export const ProvideResponsesToQuestions = () =>
  useMutation<any, unknown, any, string>(
    async ({
      responses,
      signal,
    }: {
      responses: KeyStringVal;
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${vendorPortalPrefix}/responses`,
          responses,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    }
  );
