import { useQuery, useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { queryClient } from "src/App";
import { client } from "src/components/General/AxiosInterceptor";
import { apiVersion, pageSize } from "src/constants/general";

const prefix = "grc/third_party_review";

export const AddAssessment = () =>
  useMutation<any, unknown, any, string>(
    async ({
      formData,
      signal,
    }: {
      formData: FormData;
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/new`,
          formData,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-assessment-list"]);
      },
    }
  );

export const RemoveAssessment = () => {
  const navigate = useNavigate();

  return useMutation<any, unknown, any, string>(
    async ({ reviewID, signal }: { reviewID: string; signal: AbortSignal }) => {
      try {
        const res = await client.delete(
          `/api/${apiVersion}/${prefix}/${reviewID}`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-vendor-assessments"]);
        navigate(-1);
      },
    }
  );
};

export const GetAuditStatus = (auditID: string) =>
  useQuery<any, unknown, any, (string | undefined)[]>(
    ["get-audit-status", auditID],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${prefix}/${auditID}/status`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: auditID !== "",
      keepPreviousData: false,
    }
  );

export const GetAssessmentMetadata = (assessmentID: string) =>
  useQuery<any, unknown, any, (string | undefined)[]>(
    ["get-assessment-metadata", assessmentID],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${prefix}/${assessmentID}`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: assessmentID !== "",
      keepPreviousData: false,
    }
  );

export const GetControlFilters = (assessmentID: string) =>
  useQuery<any, unknown, any, (string | undefined)[]>(
    ["get-control-filters", assessmentID],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/${assessmentID}/control_metadata`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: assessmentID !== "",
      keepPreviousData: false,
    }
  );

export const GetControls = (
  assessmentID: string,
  pageNumber: number,
  filter: string
) =>
  useMutation<any, unknown, any, string>(
    async ({
      context,
      domain,
      subDomain,
      level,
      signal,
    }: {
      context: string[];
      domain: string[];
      subDomain: string[];
      level: string[];
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/${assessmentID}/controls?filter=${filter
            .split(" ")
            .join("")}`,
          {
            pager: {
              page_number: pageNumber,
              page_size: pageSize,
            },
            ...(domain && { domain: domain }),
            ...(context && { context: context }),
            ...(subDomain && { sub_domain: subDomain }),
            ...(level && { level: level }),
          },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    }
  );

export const GetAudit = (
  selectedTab: string,
  auditID: string,
  frameworkID: string,
  pageNumber: number,
  filter: string
) =>
  useQuery<any, unknown, any, (string | number | string[])[]>(
    ["get-audit", auditID, frameworkID, pageNumber, filter],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/${auditID}/sections?filter=${filter
            .split(" ")
            .join("")}`,
          {
            framework_id: frameworkID,
            pager: {
              page_number: pageNumber,
              page_size: pageSize,
            },
          },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      keepPreviousData: false,
      enabled: selectedTab === "Audit Report" && auditID !== "",
    }
  );

export const UpdateAuditSections = (
  auditID: string,
  frameworkID: string,
  assessmentID: string
) =>
  useMutation<any, unknown, any, string>(
    async ({
      editSections,
      signal,
    }: {
      editSections: any;
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.patch(
          `/api/${apiVersion}/${prefix}/${auditID}/sections`,
          {
            framework_id: frameworkID,
            review_id: assessmentID,
            updated: editSections,
          },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-assessment-metadata"]);
        queryClient.invalidateQueries(["get-audit"]);
      },
    }
  );

export const GetAIGeneratedAnswer = (
  assessmentID: string,
  generatedID: string
) =>
  useMutation<any, unknown, any, string>(
    async ({ signal }: { signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/${assessmentID}/${generatedID}/answer`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    }
  );

export const GetControlToAuditMapping = (
  frameworkID: string,
  auditID: string,
  generatedID: string
) =>
  useMutation<any, unknown, any, string>(
    async ({ signal }: { signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/control_to_audit_mapping`,
          {
            framework_id: frameworkID,
            audit_id: auditID,
            control_id: generatedID,
          },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    }
  );

export const ExportMappings = (assessmentID: string, mapping: boolean) =>
  useMutation<any, unknown, any, string>(
    async ({ signal }: { signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/${assessmentID}/export?mapping=${mapping}&category=review`,
          { signal }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "get-third-party-export-mappings-status",
          assessmentID,
        ]);
      },
    }
  );

export const GetExportMappingStatus = (
  assessmentID: string,
  mapping: boolean
) =>
  useQuery<any, unknown, any, (string | boolean)[]>(
    ["get-third-party-export-mappings-status", assessmentID, mapping],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${prefix}/${assessmentID}/export/status?mapping=${mapping}&category=review`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: assessmentID !== "",
      keepPreviousData: false,
      refetchInterval: 30000,
    }
  );

export const DownloadMappings = (assessmentID: string, mapping: boolean) =>
  useMutation<any, unknown, any, string>(
    async ({ signal }: { signal: AbortSignal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${prefix}/${assessmentID}/export?mapping=${mapping}&category=review`,
          { signal }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "get-third-party-export-mappings-status",
          assessmentID,
        ]);
      },
    }
  );

export const GetVAScorecard = (assessmentID: string, frameworkID: string) =>
  useQuery<any, unknown, any, (string | number | string[])[]>(
    ["get-va-scorecard", assessmentID, frameworkID],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${prefix}/${assessmentID}/scorecard?framework_id=${frameworkID}`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: assessmentID !== "" && frameworkID !== "",
      keepPreviousData: true,
    }
  );
