import React from "react";
import { GetGRCDocumentMetadata } from "src/services/grc";
import { GetAuditMetadata } from "src/services/audit-management";
import { convertToUTCShortString, convertToUTCString } from "src/utils/general";
import DeleteAudit from "./DeleteAudit";
import CloseAudit from "./CloseAudit";
import ExportFile from "./ExportFile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding } from "@fortawesome/free-solid-svg-icons";

const AuditMetadata = ({
  documentType,
  documentID,
  auditID,
}: {
  documentType: string;
  documentID: string;
  auditID: string;
}) => {
  const { data: auditMetadata } = GetAuditMetadata(auditID);
  const { data: documentMetadata } = GetGRCDocumentMetadata(
    documentType,
    documentID
  );

  return (
    <>
      <header className="grid gap-5">
        {auditMetadata && documentMetadata && (
          <section className="flex flex-col flex-grow gap-1">
            <article className="flex items-center justify-between gap-20">
              <article className="flex items-center gap-2">
                <h2 className="text-t1-semi">{auditMetadata.name} </h2>
                <article className="flex items-center gap-2 px-2 py-1 break-words text-b1-reg dark:text-gray-400 dark:bg-gray-900 rounded">
                  <FontAwesomeIcon
                    icon={faBuilding}
                    className="dark:text-gray-400"
                  />
                  <span>
                    {documentMetadata.framework_name ||
                      documentMetadata.policy_name}
                  </span>
                </article>
                {documentMetadata.last_updated_at && (
                  <span className="px-2 py-1 break-words text-b1-reg dark:text-gray-400 dark:bg-gray-900 rounded">
                    {convertToUTCShortString(documentMetadata.last_updated_at)}
                  </span>
                )}
                {documentMetadata.regulatory_date && (
                  <span className="px-2 py-1 break-words text-b1-reg dark:text-gray-400 dark:bg-gray-900 rounded">
                    {convertToUTCShortString(documentMetadata.regulatory_date)}
                  </span>
                )}
                {(documentMetadata.regulatory_authority ||
                  documentMetadata.customer_name) && (
                  <span className="px-2 py-1 break-words text-b1-reg dark:text-gray-400 dark:bg-gray-900 rounded">
                    {documentMetadata.regulatory_authority ||
                      documentMetadata.customer_name}
                  </span>
                )}
              </article>
              {documentMetadata && (
                <article className="flex items-center gap-5">
                  <DeleteAudit auditID={auditID} />
                  {auditMetadata.is_closed ? (
                    <article className="flex items-center gap-5">
                      <ExportFile auditID={auditID} />
                      <span className="px-4 py-2 dark:bg-gray-700 rounded-xl">
                        Closed
                      </span>
                    </article>
                  ) : (
                    <CloseAudit auditID={auditID} />
                  )}
                </article>
              )}
            </article>
            <span className="text-b1-reg dark:text-gray-400">
              created at {convertToUTCString(auditMetadata.created_at)}
            </span>
          </section>
        )}
      </header>
    </>
  );
};

export default AuditMetadata;
