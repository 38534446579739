import { GetAgreementProposalReviewMetadata } from "src/services/agreement-proposal-review";
import { convertToUTCShortString } from "src/utils/general";

const AgreementProposalReviewMetadata = ({
  documentID,
}: {
  documentID: string;
}) => {
  const { data: metadata } = GetAgreementProposalReviewMetadata(documentID);

  return (
    <>
      {metadata && (
        <header className="grid content-start">
          <header className="flex flex-wrap items-center gap-5 break-words">
            <h4 className="text-h5">{metadata.agreement_name}</h4>
            <article className="flex items-center gap-2 px-2 py-1 text-b1-reg dark:bg-blue-800 rounded">
              <p>{metadata.counts} items to review</p>
              <span className="dark:text-blue-300">
                {convertToUTCShortString(Number(metadata.agreement_date))}
              </span>
            </article>
          </header>
          {metadata.tags?.length > 0 && (
            <article className="flex flex-wrap items-center gap-2">
              <span>Tags</span>
              {metadata.tags.map((tag: string, index: number) => {
                return (
                  <span key={index} className="px-4 dark:bg-org rounded-full">
                    {tag}
                  </span>
                );
              })}
            </article>
          )}
        </header>
      )}
    </>
  );
};

export default AgreementProposalReviewMetadata;
