import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import ModalLayout from "src/layouts/ModalLayout";
import { RemoveDocument } from "../../../../../../services/third-party-risk/vendors/vendors";

const DeleteDocument = ({
  vendorID,
  assessmentID,
  documentID,
}: {
  vendorID: string;
  assessmentID: string;
  documentID: string;
}) => {
  const [show, setShow] = useState<boolean>(false);

  const removeDocument = RemoveDocument(vendorID, assessmentID, documentID);

  const deleting = removeDocument.status === "loading";

  const handleOnClose = () => setShow(false);

  return (
    <>
      <button
        disabled={deleting}
        className="red-trash-button"
        onClick={(e) => {
          e.stopPropagation();
          setShow(true);
        }}
      >
        <FontAwesomeIcon icon={faTrashCan} />
      </button>
      <ModalLayout showModal={show} onClose={() => setShow(false)}>
        <section className="grid gap-10">
          <h2 className="text-subt1-semi">Delete Document</h2>
          <h4 className="text-b1-reg">
            Are you sure you want to delete this document?
          </h4>
          <article className="flex items-center place-content-end gap-5">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={deleting}
              className="red-button"
              onClick={() => {
                removeDocument.mutate({});
                handleOnClose();
              }}
            >
              Delete
            </button>
          </article>
        </section>
      </ModalLayout>
    </>
  );
};

export default DeleteDocument;
