import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import ModalLayout from "src/layouts/ModalLayout";
import { RemoveAudit } from "src/services/audit-management";

const DeleteAudit = ({ auditID }: { auditID: string }) => {
  const [show, setShow] = useState<boolean>(false);

  const removeAudit = RemoveAudit();

  const handleOnClose = () => setShow(false);

  const deleting = removeAudit.status === "loading";

  return (
    <>
      <button
        disabled={deleting}
        className="px-4 py-2 text-b1-reg dark:text-red-500 dark:bg-gray-900 dark:hover:bg-gray-900/70 duration-100 rounded-xl"
        onClick={() => setShow(true)}
      >
        Delete Audit
      </button>
      <ModalLayout showModal={show} onClose={() => setShow(false)}>
        <section className="grid gap-10">
          <h2 className="text-subt1-semi">Delete Internal Audit</h2>
          <h4 className="text-b1-reg">
            Are you sure you want to delete this internal audit?
          </h4>
          <article className="flex items-center place-content-end gap-5">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={deleting}
              className="red-button"
              onClick={() => {
                removeAudit.mutate({
                  auditID: auditID,
                });
                handleOnClose();
              }}
            >
              Delete
            </button>
          </article>
        </section>
      </ModalLayout>
    </>
  );
};

export default DeleteAudit;
