/* eslint-disable no-restricted-globals */
import DropdownLayout from "../../../layouts/DropdownLayout";
import { KeyStringVal } from "src/types/general";
import { GetCustomQuestionSets } from "src/services/third-party-risk/questions/custom-question-sets";

const SelectQuestionSetFilter = ({
  selectedQuestionSet,
  setSelectedQuestionSet,
}: {
  selectedQuestionSet: KeyStringVal;
  setSelectedQuestionSet: (selectedQuestionSet: KeyStringVal) => void;
}) => {
  const { data: questionSets } = GetCustomQuestionSets();

  return (
    <DropdownLayout
      value={selectedQuestionSet.document_name}
      placeholder="Select Question Set"
    >
      {questionSets?.data.map((questionSet: KeyStringVal, index: number) => (
        <button
          key={index}
          className="relative flex items-center gap-2 px-4 py-1 w-full break-words text-left dark:hover:bg-gray-600/70 duration-100"
          onClick={() => setSelectedQuestionSet(questionSet)}
        >
          <p>{questionSet.name}</p>
        </button>
      ))}
    </DropdownLayout>
  );
};

export default SelectQuestionSetFilter;
