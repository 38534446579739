/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import { useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/solid";

const TextFilter = ({
  label,
  list,
  value,
  setValue,
  showAbove,
  width,
  searchable,
}: {
  label?: string;
  list: string[];
  value: string;
  setValue: (value: string) => void;
  showAbove?: boolean;
  width?: string;
  searchable?: boolean;
}) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [query, setQuery] = useState<string>("");

  const filteredList = searchable
    ? list?.filter((value: string) =>
        value
          .toLowerCase()
          .replace(/\s+/g, "")
          .includes(String(query).toLowerCase().replace(/\s+/g, ""))
      )
    : list;

  return (
    <>
      <section
        className="flex items-center gap-3 text-sm"
        onMouseLeave={() => setShowDropdown(false)}
      >
        {label && (
          <h4 className="max-w-60 text-sm dark:text-checkbox">{label}</h4>
        )}
        <article
          onMouseEnter={() => setShowDropdown(true)}
          className={`relative px-4 py-1 ${
            width ? width : "w-full"
          } text-left cursor-pointer dark:bg-gray-700 dark:hover:bg-gray-700/60 duration-100 focus:outline-none rounded-t-sm`}
        >
          <article className="flex items-center justify-between gap-2">
            {searchable ? (
              <input
                type="input"
                autoComplete="off"
                spellCheck="false"
                placeholder="Select"
                value={query ? query : value}
                onChange={(e) => {
                  if (!showDropdown) setShowDropdown(true);
                  if (searchable) setQuery(e.target.value);
                }}
                className={`${
                  width ? width : ""
                } border-transparent focus:ring-0 focus:border-transparent bg-transparent focus:outline-none`}
              />
            ) : (
              <span className="pr-2 break-words">{value || "Select"}</span>
            )}
            <span className="flex items-center pointer-events-none">
              <ChevronDownIcon className="w-5 h-5" aria-hidden="true" />
            </span>
          </article>
          {showDropdown && (
            <article
              className={`absolute ${
                showAbove ? "bottom-7" : "top-7"
              } left-0 grid content-start w-full max-h-36 dark:bg-gray-700 focus:outline-none overflow-auto scrollbar rounded-b-sm`}
            >
              {filteredList?.map((item: string) => (
                <button
                  key={item}
                  className={`relative px-4 py-1 text-left dark:hover:bg-gray-600/70 ${
                    value === item ? "dark:bg-filter" : ""
                  } duration-200`}
                  onClick={() => {
                    setValue(item);
                    setQuery("");
                  }}
                >
                  <p>{item}</p>
                </button>
              ))}
            </article>
          )}
        </article>
      </section>
    </>
  );
};

export default TextFilter;
