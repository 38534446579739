/* eslint-disable no-restricted-globals */
/* eslint-disable react-hooks/exhaustive-deps */
import { MutableRefObject, useEffect, useRef, useState } from "react";
import OTPInput from "./Login/TwoFA/OTPInput";
import SessionLayout from "../layouts/SessionLayout";
import {
  convertToUTCShortString,
  convertToUTCString,
  parseURL,
} from "../utils/general";
import {
  ProvideResponsesToQuestions,
  RetrieveJWTForVendor,
  RetrieveVendorQuestions,
} from "../services/third-party-risk/vendors/vendors";
import React from "react";
import TablePagination from "../components/General/TablePagination";
import { pageSize } from "../constants/general";
import jwt_decode from "jwt-decode";

const VendorWebform = () => {
  const parsed = parseURL();
  const vendorID = String(parsed.third_party_id);
  const assessmentID = String(parsed.assessment_id);
  const vendorJwt =
    sessionStorage.vendorJwt && (jwt_decode(sessionStorage.vendorJwt) as any);

  const inputRef = useRef([]) as MutableRefObject<HTMLInputElement[]>;

  const [otpCode, setOTPCode] = useState<string>("");
  const [nav, setNav] = useState<number>(1);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [responses, setResponses] = useState({});

  const retrieveJWT = RetrieveJWTForVendor(vendorID, assessmentID);
  const questions = RetrieveVendorQuestions(pageNumber);
  const provideResponses = ProvideResponsesToQuestions();

  const totalCount = questions?.data?.pager?.total_results || 0;
  const totalPages = Math.ceil(totalCount / pageSize);
  const beginning =
    pageNumber === 1 ? 1 : pageSize * ((pageNumber || 1) - 1) + 1;
  const end = pageNumber === totalPages ? totalCount : beginning + pageSize - 1;

  useEffect(() => {
    if (otpCode.length === 6) {
      const formData = new FormData();
      formData.append("hotp_code", String(otpCode));
      retrieveJWT.mutate(
        {
          formData,
        },
        {
          onSuccess: (data) => {
            if (data) {
              setNav(2);
              sessionStorage.vendorJwt = data;
              questions.mutate({});
            } else {
              setOTPCode("");
              if (inputRef?.current) inputRef?.current[0]?.focus();
            }
          },
        }
      );
    }
  }, [otpCode]);

  return (
    <SessionLayout>
      <h4 className="text-t1-semi">Vendor Assessment</h4>

      {/* if current time passes the expiration time  */}
      {vendorJwt?.scope.expired ||
      Date.now() * 1000 > vendorJwt?.scope?.end_date ? (
        <section className="grid py-10 gap-3 text-center dark:bg-gray-900">
          <p>The link has expired......</p>
          <p>Check with your customer to send a new email.</p>
        </section>
      ) : nav === 1 ? (
        <section className="grid content-start gap-5 p-10 dark:bg-gray-900">
          <p className="text-xl tracking wide">Enter OTP</p>

          {retrieveJWT.status === "success" && !retrieveJWT.data && (
            <p className="p-2 mx-auto w-max text-xs text-left uppercase dark:bg-inner border dark:border-error rounded-sm">
              OTP code is not correct. Please try again.
            </p>
          )}

          <OTPInput
            otpCode={otpCode}
            setOTPCode={setOTPCode}
            codeLength={6}
            inputRef={inputRef}
          />
        </section>
      ) : nav === 2 ? (
        <section className="flex flex-col flex-grow gap-5">
          {vendorJwt && (
            <header className="flex flex-col flex-grow gap-5 text-b1-reg text-left">
              <article className="grid gap-2">
                <h4 className="text-h5">Assesssment</h4>
                <article className="flex flex-wrap items-center justify-between gap-20 pb-2">
                  <h2 className="text-2xl">
                    {vendorJwt.scope.assessment_name}
                  </h2>
                  <article className="flex flex-wrap items-center gap-3">
                    {convertToUTCShortString(vendorJwt.scope.start_date)} -{" "}
                    {convertToUTCShortString(vendorJwt.scope.end_date)}
                  </article>
                </article>
              </article>
              <article className="grid gap-2">
                <h4 className="text-h5">Vendor</h4>
                <span>{vendorJwt.scope.vendor_name}</span>
              </article>
            </header>
          )}
          <h4>Vendor Questions</h4>
          <span className="text-b3-reg">
            Please fill out the response for each question
          </span>
          <ul className="flex flex-col flex-grow gap-5">
            {questions?.data?.data?.map((qa: any, index: number) => {
              return (
                <li
                  key={index}
                  className="grid gap-2 p-5 w-full dark:bg-gray-700 rounded"
                >
                  <p className="text-left"> Q: {qa.question}</p>
                  {qa.question_type === "Yes_No" ? (
                    <section className="flex items-center gap-5">
                      {["Yes", "No"].map((questionType) => {
                        return (
                          <article
                            key={questionType}
                            className="flex items-center gap-1"
                          >
                            <input
                              type="radio"
                              className="form-radio w-4 h-4 dark:bg-transparent dark:ring-0 dark:text-blue-500 dark:focus:border-blue-600 focus:ring dark:focus:ring-offset-0 dark:focus:ring-blue-600 focus:ring-opacity-50 rounded-full"
                              checked={
                                responses[qa.question]?.response ===
                                questionType
                              }
                              onChange={() =>
                                setResponses({
                                  ...responses,
                                  [qa.question]: {
                                    ...responses[qa.question],
                                    response: questionType,
                                  },
                                })
                              }
                            />
                            <label htmlFor="">
                              {questionType === "Yes_No" ? "Y/N" : questionType}
                            </label>
                          </article>
                        );
                      })}
                    </section>
                  ) : (
                    <article className="flex items-start gap-2 w-full">
                      A:{" "}
                      <textarea
                        value={responses[qa.question]?.response}
                        onChange={(e) =>
                          setResponses({
                            ...responses,
                            [qa.question]: {
                              ...responses[qa.question],
                              response: e.target.value,
                            },
                          })
                        }
                        className="px-4 py-2 w-full h-32 focus:outline-none text-b2-reg placeholder:text-b2-reg dark:placeholder:text-gray-500 dark:disabled:text-checkbox dark:bg-gray-900 dark:border-transparent dark:focus:ring dark:focus:ring-offset-blue-500/50 dark:focus:ring-offset dark:focus:border dark:focus:border-blue-500 resize-none rounded"
                      />
                    </article>
                  )}
                </li>
              );
            })}
          </ul>
          <TablePagination
            totalPages={totalPages}
            beginning={beginning}
            end={end}
            totalCount={totalCount}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
          <button
            disabled={provideResponses.status === "loading"}
            className="place-self-center blue-button"
            onClick={() =>
              provideResponses.mutate(
                {
                  responses,
                },
                {
                  onSuccess: () => setNav(3),
                }
              )
            }
          >
            Submit
          </button>
        </section>
      ) : nav === 3 ? (
        <section className="grid content-start p-10 gap-5 w-full h-full text-b2-reg text-center dark:bg-gray-900">
          <h4 className="text-b1-reg">{vendorJwt?.scope.vendor_name}</h4>
          <p>
            You have successfully submitted the vendor assesssment! You can
            close this browser now.
          </p>
          <span className="text-b3-reg">
            Submitted at: {convertToUTCString(Date.now() * 1000)}
          </span>
        </section>
      ) : null}
    </SessionLayout>
  );
};

export default VendorWebform;
