/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";
import UserInput from "../../../../../../components/Input/UserInput";
import { Popover, Transition } from "@headlessui/react";
import { faNoteSticky } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  GetQnANotes,
  PostQnANote,
} from "../../../../../../services/third-party-risk/vendors/vendors";

const Notes = ({
  vendorID,
  assessmentID,
  questionID,
}: {
  vendorID: string;
  assessmentID: string;
  questionID: string;
}) => {
  const [inputs, setInputs] = useState({
    user_id: "",
    notes: "",
  });

  const { data: qnaNotes } = GetQnANotes(vendorID, assessmentID, questionID);
  const postQnA = PostQnANote(vendorID, assessmentID, questionID);

  const populateNotes = () => {
    if (qnaNotes)
      setInputs({
        user_id: qnaNotes.assigned_to_user || "",
        notes: qnaNotes.notes_txt || "",
      });
  };

  useEffect(() => {
    populateNotes();
  }, [qnaNotes]);

  return (
    <Popover className="relative">
      <Popover.Button className="flex items-center gap-1 dark:hover:bg-filter/30 duration-100 rounded-full">
        <h4>
          <FontAwesomeIcon
            icon={faNoteSticky}
            className={`${inputs.notes ? "text-blue-500" : ""}`}
          />{" "}
          Notes
        </h4>
      </Popover.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
        beforeEnter={populateNotes}
      >
        <Popover.Panel className="pointer-events-auto absolute bottom-8 grid gap-2 p-4 break-all dark:bg-panel rounded-md">
          <UserInput
            label="Assigned to"
            keyName="user_id"
            inputs={inputs}
            setInputs={setInputs}
            allowUnselect
          />
          <article className="grid gap-2">
            <h4>Notes</h4>
            <textarea
              value={inputs.notes}
              onChange={(e) => setInputs({ ...inputs, notes: e.target.value })}
              className="px-3 py-1 w-full h-40 dark:bg-black focus:outline-none dark:focus:ring-0 dark:focus:border-white resize-none overflow-auto scrollbar"
            />
          </article>
          <button
            disabled={
              postQnA.status === "loading" || Object.values(inputs).includes("")
            }
            className="blue-button"
            onClick={() =>
              postQnA.mutate({
                user: inputs.user_id,
                notes: inputs.notes,
              })
            }
          >
            Save
          </button>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default Notes;
