import {
  faDownload,
  faFileExport,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { LineWave } from "react-loader-spinner";
import {
  DownloadControlTable,
  ExportControlTable,
  GetControlTableExportStatus,
} from "../../../../../services/regulation-policy/overview";

const ExportFile = ({
  documentID,
  type,
}: {
  documentID: string;
  type: string;
}) => {
  const exportFile = ExportControlTable(documentID, type);
  const { data: exportStatus } = GetControlTableExportStatus(documentID, type);
  const downloadFile = DownloadControlTable(documentID, type);

  const onDownload = () => {
    downloadFile.mutate(
      {},
      {
        onSuccess: (data) => {
          const url = data;
          let a = document.createElement("a");
          a.href = url;
          a.download = "control_table.docx";
          a.target = "_blank";
          a.click();
        },
      }
    );
  };

  return (
    <article className="flex items-center gap-5 place-self-end">
      <button
        className="flex items-center gap-1 text-left dark:hover:text-checkbox/60 duration-100"
        onClick={() => {
          exportFile.mutate({});
        }}
      >
        <FontAwesomeIcon icon={faFileExport} className="text-blue-600" />
        Export
      </button>
      {exportStatus?.status === "triggered" ? (
        <span className="flex items-center gap-1">
          Exporting{" "}
          <LineWave
            visible={true}
            height="30"
            width="30"
            color="#4fa94d"
            ariaLabel="line-wave-loading"
            wrapperStyle={{}}
            wrapperClass=""
            firstLineColor=""
            middleLineColor=""
            lastLineColor=""
          />
        </span>
      ) : exportStatus?.status === "ready" ? (
        <button
          className="flex items-center gap-1 px-4 py-1 dark:bg-blue-600 dark:hover:bg-blue-600/70 duration-100 rounded-md"
          onClick={onDownload}
        >
          <FontAwesomeIcon icon={faDownload} className="w-4 h-4" />
          Download as File
        </button>
      ) : exportStatus?.status === "failed" ? (
        <span>
          <FontAwesomeIcon
            icon={faTriangleExclamation}
            className="text-reset"
          />{" "}
          Export failed
        </span>
      ) : null}
    </article>
  );
};

export default ExportFile;
