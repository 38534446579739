/* eslint-disable react-hooks/exhaustive-deps */
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChevronDownIcon } from "@heroicons/react/solid";
import React, { useEffect, useState } from "react";
import { SearchGRCVerticals } from "src/services/regulation-policy/overview";
import { KeyStringVal } from "src/types/general";

const VerticalFilter = ({
  label,
  inputs,
  setInputs,
}: {
  label?: string;
  inputs: any;
  setInputs: (inputs: KeyStringVal) => void;
}) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [query, setQuery] = useState<string>("");

  const search = SearchGRCVerticals();

  const handleSelect = (vertical: KeyStringVal) => {
    const value = `${vertical.industry}-${vertical.sub_category}`;
    if (inputs.verticals.includes(value))
      setInputs({
        ...inputs,
        verticals: inputs.verticals.filter(
          (curVertical: string) => curVertical !== value
        ),
      });
    else setInputs({ ...inputs, verticals: [...inputs.verticals, value] });
    setQuery("");
  };

  useEffect(() => {
    search.mutate({
      vertical: "",
    });
  }, []);

  return (
    <section className="flex items-center gap-3 text-b2-reg text-left">
      {label && <h4>{label}</h4>}
      <article
        onMouseLeave={() => setShowDropdown(false)}
        onClick={() => setShowDropdown(true)}
        className="relative px-4 py-1 w-[20rem] dark:bg-gray-700 dark:hover:bg-gray-700/60 duration-100 focus:outline-none rounded-sm"
      >
        <article className="flex items-center justify-between gap-2">
          <input
            type="input"
            autoComplete="off"
            spellCheck="false"
            placeholder={`${
              inputs.verticals.length > 0
                ? `${inputs.verticals.length} selected`
                : "Please select"
            }`}
            value={query}
            onChange={(e) => {
              if (!showDropdown) setShowDropdown(true);
              setQuery(e.target.value);
              search.mutate({
                vertical: e.target.value,
              });
            }}
            className="w-full border-transparent focus:ring-0 focus:border-transparent bg-transparent focus:outline-none"
          />
          <span className="flex items-center pointer-events-none">
            <ChevronDownIcon className="w-5 h-5" aria-hidden="true" />
          </span>
        </article>
        {showDropdown && (
          <article className="absolute top-8 right-0 grid content-start w-full max-h-36 dark:bg-gray-700 focus:outline-none overflow-auto scrollbar rounded-b-sm z-10">
            {search.data ? (
              search.data.length > 0 ? (
                <article className="flex flex-col flex-grow gap-1">
                  <article className="flex items-center gap-5 px-4 py-1 divide-x dark:divide-checkbox">
                    <button
                      className="dark:disabled:text-filter dark:hover:text-checkbox/60 duration-100"
                      onClick={() =>
                        search.mutate(
                          {
                            vertical: "",
                          },
                          {
                            onSuccess: (verticalData) => {
                              const verticals = verticalData.reduce(
                                (pV: string[], cV: KeyStringVal) => [
                                  ...pV,
                                  `${cV.industry}-${cV.sub_category}`,
                                ],
                                []
                              );
                              setInputs({
                                ...inputs,
                                verticals: verticals,
                              });
                            },
                          }
                        )
                      }
                    >
                      Select All
                    </button>
                    <button
                      disabled={inputs.verticals.length === 0}
                      className="pl-5 dark:disabled:text-filter dark:hover:text-checkbox/60 duration-100"
                      onClick={() => setInputs({ ...inputs, verticals: [] })}
                    >
                      Deselect All
                    </button>
                  </article>
                  {search.data?.map((item: KeyStringVal) => (
                    <button
                      key={`${item.industry}-${item.sub_category}`}
                      className="relative group flex gap-1 px-4 py-1 text-left break-words cursor-pointer dark:hover:bg-gray-600/70 duration-100"
                      onClick={() => handleSelect(item)}
                    >
                      {inputs.verticals.includes(
                        `${item.industry}-${item.sub_category}`
                      ) && (
                        <FontAwesomeIcon icon={faCheck} className="text-no" />
                      )}
                      {item.industry} - {item.sub_category}
                    </button>
                  ))}
                </article>
              ) : (
                <p className="px-4 py-1">No results found</p>
              )
            ) : null}
          </article>
        )}
      </article>
    </section>
  );
};

export default VerticalFilter;
