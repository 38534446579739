/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import Sections from "./Sections/Sections";
import AuditTabs from "./AuditTabs";
import Filters from "./Sections/Filters";

const AuditDetail = ({
  documentType,
  documentID,
  auditID,
}: {
  documentType: string;
  documentID: string;
  auditID: string;
}) => {
  const [selectedTab, setSelectedTab] = useState("controls");
  const [filters, setFilters] = useState({
    filter: "All",
    hide_excluded: false,
    analysis_generated: false,
    has_evidence: false,
  });

  return (
    <section className="flex flex-col flex-grow gap-5 mb-4">
      <header className="flex items-center gap-5">
        <AuditTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
        <Filters filters={filters} setFilters={setFilters} />
      </header>
      <Sections
        documentType={documentType}
        documentID={documentID}
        auditID={auditID}
        selectedTab={selectedTab}
        filters={filters}
      />
    </section>
  );
};

export default AuditDetail;
