import {
  faChevronDown,
  faChevronRight,
  faArrowLeftLong,
  faWarning,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { KeyStringVal } from "src/types/general";
import Loader from "src/components/Loader/Loader";
import { pageSize } from "src/constants/general";
import TablePagination from "src/components/General/TablePagination";
import { GetCustomQuestions } from "src/services/third-party-risk/questions/custom-question-sets";
import { Disclosure } from "@headlessui/react";
import { GetQuestionTypes } from "../../../../../services/third-party-risk/questions/global-questions";

const CustomQuestionSetDetail = ({
  selectedQuestionSet,
  setSelectedQuestionSet,
}: {
  selectedQuestionSet: KeyStringVal;
  setSelectedQuestionSet: (selectedQuestionSet: KeyStringVal) => void;
}) => {
  const [pageNumber, setPageNumber] = useState<number>(1);

  const questionSetID = sessionStorage.custom_question_set_id;

  const { data: questionSet, status: questionSetStatus } = GetCustomQuestions(
    questionSetID,
    pageNumber
  );
  const { data: questionTypes } = GetQuestionTypes();

  const totalCount = questionSet?.pager?.total_results || 0;
  const totalPages = questionSet?.pager?.num_pages || 0;
  const beginning = pageNumber === 1 ? 1 : pageSize * (pageNumber - 1) + 1;
  const end = pageNumber === totalPages ? totalCount : beginning + pageSize - 1;

  const handleReturn = () => {
    sessionStorage.removeItem("custom_question_set_id");
    sessionStorage.removeItem("custom_question_set_name");
    setSelectedQuestionSet({});
  };

  return (
    <section className="flex flex-col flex-grow gap-5">
      <header className="flex items-center gap-5">
        <button
          className="flex gap-2 items-center w-max tracking-wide text-sm dark:text-checkbox dark:hover:text-checkbox/50 duration-100"
          onClick={handleReturn}
        >
          <FontAwesomeIcon icon={faArrowLeftLong} />
          <span>Return</span>
        </button>
        <h4 className="text-xl">{sessionStorage.custom_question_set_name}</h4>
      </header>
      {questionSetStatus === "loading" ? (
        <Loader />
      ) : questionSet?.data.length > 0 ? (
        <section className="flex flex-col flex-grow gap-5 pb-20">
          <ul className="flex flex-col flex-grow gap-5 text-base">
            {questionSet.data.map((qa: any, index: number) => {
              return (
                <li
                  key={index}
                  className="grid gap-5 p-5 dark:bg-gray-700 rounded-md"
                >
                  <h4>Q: {qa.question}</h4>
                  <Disclosure>
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex items-center gap-2 text-sm">
                          <FontAwesomeIcon
                            icon={open ? faChevronDown : faChevronRight}
                          />
                          <p>{open ? "Hide" : "Show"} Guidelines</p>
                        </Disclosure.Button>
                        <Disclosure.Panel>
                          <article className="flex gap-2 text-base p-3 break-words dark:bg-black/60 rounded-md">
                            {qa.question_type === "Yes_No" ? (
                              <section className="flex items-center gap-5">
                                {["Yes", "No"].map((questionType) => {
                                  return (
                                    <article
                                      key={questionType}
                                      className="flex items-center gap-1"
                                    >
                                      <input
                                        type="radio"
                                        disabled
                                        className="form-radio w-4 h-4 dark:bg-transparent dark:ring-0 dark:text-blue-500 dark:focus:border-blue-600 focus:ring dark:focus:ring-offset-0 dark:focus:ring-blue-600 focus:ring-opacity-50 rounded-full"
                                        checked={
                                          qa.ideal_response?.toLowerCase() ===
                                          questionType.toLowerCase()
                                        }
                                      />
                                      <label htmlFor="">
                                        {questionType === "Yes_No"
                                          ? "Y/N"
                                          : questionType}
                                      </label>
                                    </article>
                                  );
                                })}
                                {!["yes", "no"].includes(
                                  qa.ideal_response?.toLowerCase()
                                ) && (
                                  <article>
                                    <FontAwesomeIcon
                                      icon={faWarning}
                                      className="text-red-700"
                                    />{" "}
                                    Invalid response
                                  </article>
                                )}
                              </section>
                            ) : (
                              <p className="flex items-start gap-2 w-full">
                                A: {qa.ideal_response}
                              </p>
                            )}
                          </article>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                </li>
              );
            })}
          </ul>
          <TablePagination
            totalPages={totalPages}
            beginning={beginning}
            end={end}
            totalCount={totalCount}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
        </section>
      ) : (
        <section className="flex items-center place-content-center gap-10 w-full h-full">
          <img
            src="/grc/third-party-risk-placeholder.svg"
            alt="questionSet placeholder"
            className="w-40 h-40"
          />
          <article className="grid gap-3">
            <h4 className="text-xl font-extrabold">Custom Question Set</h4>
            <h4>No questions available</h4>
          </article>
        </section>
      )}
    </section>
  );
};

export default CustomQuestionSetDetail;
