import {
  faCheck,
  faChevronRight,
  faComment,
  faExclamationTriangle,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import EvidenceList from "./EvidenceList";
import { handleClickMapping } from "src/utils/grc";
import ControlContent from "./ControlContent";
import { AnimatePresence } from "framer-motion";
import DiscussionNotes from "../../../../AgreementProposalReviews/AgreementProposalReview/AgreementReview/DiscussionNotes/DiscussionNotes";
import { useGRCStore } from "src/stores/grc";
import { GetGRCDiscussionCount } from "../../../../../services/grc";
import Analysis from "./Analysis";

const Control = ({
  documentType,
  documentID,
  documentName,
  auditID,
  control,
}: {
  documentType: string;
  documentID: string;
  documentName: string;
  auditID: string;
  control: any;
}) => {
  const {
    showGRCPanel,
    selectedAnchorID,
    setShowGRCPanel,
    setSelectedAnchorID,
    setSelectedGRCPanelTab,
  } = useGRCStore();

  const controlID = control.generated_id;
  const selectedTab = "Controls Coverage";

  const { data: discussionCounts } = GetGRCDiscussionCount(auditID);

  const discussionCount =
    discussionCounts?.find((anchor) => anchor.anchor_id === controlID)?.count ||
    0;

  return (
    <article className="relative grid content-start gap-3 px-6 py-4 dark:bg-gray-900 rounded-lg">
      <article className="flex items-start justify-between gap-10">
        <article className="grid gap-2 w-2/5">
          <h4 className="flex items-start gap-2 text-b1-semi">
            {control.sub_section_id !== "-" && control.sub_section_id}{" "}
            {control.sub_section_title !== "-" && control.sub_section_title}
          </h4>
          <article className="flex flex-wrap items-center gap-2">
            {!control.has_evidence && (
              <span className="flex items-center gap-1 px-2 py-1 text-b1-reg dark:bg-gray-800 rounded">
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  className="text-yellow-500"
                />
                No Evidence Uploaded
              </span>
            )}
            {control.excluded && (
              <span className="flex items-center gap-1 px-2 py-1 text-b1-reg dark:bg-gray-800 rounded">
                <FontAwesomeIcon
                  icon={faEyeSlash}
                  className="text-yellow-500"
                />
                Excluded
              </span>
            )}
            {control.analysis_generated && (
              <span className="flex items-center gap-1 px-2 py-1 text-b1-reg dark:bg-gray-800 rounded">
                <FontAwesomeIcon icon={faCheck} className="text-no" />
                Analysis Generated
              </span>
            )}
            {control.metadata_ && (
              <ul className="flex items-center gap-2">
                {Object.entries(control.metadata_).map((keyVal, index) => {
                  return (
                    <li
                      key={keyVal[0]}
                      className="flex items-center gap-2 px-2 py-1 dark:bg-gray-800 rounded"
                    >
                      {keyVal[0] === "excluded" && keyVal[1] ? (
                        <span>Excluded</span>
                      ) : (
                        <>
                          <h4 className="capitalize">
                            {keyVal[0].replaceAll("_", " ")}
                          </h4>
                          {Array.isArray(keyVal[1]) ? (
                            keyVal[1].map((value) => {
                              return (
                                <span
                                  key={value}
                                  className="text-b1-reg dark:text-blue-600 dark:bg-gray-800"
                                >
                                  {value}
                                </span>
                              );
                            })
                          ) : (
                            <span className="flex items-center gap-1 text-b1-reg dark:text-blue-600 dark:bg-gray-800">
                              {keyVal[1]}
                            </span>
                          )}
                        </>
                      )}
                    </li>
                  );
                })}
              </ul>
            )}
          </article>
        </article>
        <article className="flex items-center place-content-end gap-2 text-b2-reg">
          <EvidenceList auditID={auditID} controlID={controlID} />
          <Analysis auditID={auditID} controlID={controlID} />
          <button
            className="flex items-center gap-1 px-4 py-1 capitalize dark:bg-black/40 rounded-lg"
            onClick={() => {
              setShowGRCPanel(!showGRCPanel);
              setSelectedGRCPanelTab("comments");
              setSelectedAnchorID(controlID);
            }}
          >
            <FontAwesomeIcon icon={faComment} />
            Comments
            <span>({discussionCount})</span>
          </button>
          <a
            href="/grc/mapping"
            className="flex items-center gap-2 dark:text-blue-600 dark:hover:text-blue-600/80 duration-100"
            onClick={() =>
              handleClickMapping(
                documentType,
                documentName,
                documentID,
                selectedTab,
                control,
                "Relevant Sections",
                control.mapped_self_control_sections
              )
            }
          >
            <FontAwesomeIcon icon={faChevronRight} />
            <p>
              {control.mapped_self_control_sections === 0
                ? "Relevant Sections"
                : `${control.mapped_self_control_sections} Relevant Section${
                    control.mapped_self_control_sections === 1 ? "" : "s"
                  }`}
            </p>
          </a>
        </article>
      </article>

      {control.content && <ControlContent subsection={control} />}
      <AnimatePresence exitBeforeEnter>
        {showGRCPanel && selectedAnchorID === controlID && (
          <DiscussionNotes
            documentID={auditID}
            anchorID={controlID}
            title={control.sub_section_title}
          />
        )}
      </AnimatePresence>
    </article>
  );
};

export default Control;
