import { useMutation, useQuery } from "react-query";
import { client } from "src/components/General/AxiosInterceptor";
import { apiVersion, pageSize } from "src/constants/general";
import { KeyStringVal } from "src/types/general";
import { queryClient } from "src/App";

const prefix = "grc/contractual_agreements";

export const GetAgreementProposalReviews = (
  order: KeyStringVal,
  pageNumber: number
) =>
  useQuery<any, unknown, any, (string | number | KeyStringVal)[]>(
    ["get-agreement-proposal-reviews", order, pageNumber],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}`,
          {
            ...(order.orderBy !== "" && {
              order_by: order.orderBy,
              direction: order.order,
            }),
            page_size: pageSize,
            page_number: pageNumber,
          },
          { signal }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      keepPreviousData: false,
    }
  );

export const ParseAgreementProposalReviewMetadata = () =>
  useMutation<any, unknown, any, string>(
    async ({
      formData,
      signal,
    }: {
      formData: FormData;
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/parse`,
          formData,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    }
  );

export const CreateAgreement = () =>
  useMutation<any, unknown, any, string>(
    async ({
      formData,
      signal,
    }: {
      formData: FormData;
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/new_contractual_agreement`,
          formData,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () =>
        queryClient.invalidateQueries(["get-agreement-proposal-reviews"]),
    }
  );

export const GetAgreementProposalReviewMetadata = (documentID: string) =>
  useQuery<any, unknown, any, (string | undefined)[]>(
    ["get-agreement-proposal-review-metadata", documentID],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${prefix}/${documentID}`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: documentID !== "",
      keepPreviousData: false,
    }
  );

export const GetAgreement = (documentID: string, mySections: boolean) =>
  useQuery<any, unknown, any, (string | boolean | KeyStringVal | string[])[]>(
    ["get-agreement", documentID, mySections],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${prefix}/mapping?contractual_agreement_id=${documentID}&my_sections=${mySections}`,
          { signal }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: documentID !== "",
      keepPreviousData: false,
      refetchInterval: 100000,
    }
  );

export const GetAgreementSections = (documentID: string, pageNumber: number) =>
  useQuery<any, unknown, any, (string | number)[]>(
    ["get-agreement-sections", documentID, pageNumber],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/sections`,
          {
            contractual_agreement_id: documentID,
            pager: { page_size: pageSize, page_number: pageNumber },
          },
          { signal }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: documentID !== "",
      keepPreviousData: false,
    }
  );

export const AskQuestion = (documentID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ question, signal }: { question: string; signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/submit_question/${documentID}`,
          { question: [question] },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => queryClient.invalidateQueries(["get-agreement"]),
    }
  );
