import {
  faChevronDown,
  faChevronRight,
  faCheck,
  faXmark,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Disclosure } from "@headlessui/react";
import React, { useState } from "react";
import TablePagination from "src/components/General/TablePagination";
import Loader from "src/components/Loader/Loader";
import { pageSize, attributeColors } from "src/constants/general";
import { GetDocumentStatus, GetGRCDocumentMetadata } from "src/services/grc";
import { convertToUTCString } from "src/utils/general";
import ControlDetail from "../ControlDetail/ControlDetail";
import { GetInternalAuditSections } from "src/services/audit-management";
import SectionAnalysis from "./SectionAnalysis";

const Sections = ({
  documentType,
  documentID,
  auditID,
  selectedTab,
  filters,
}: {
  documentType: string;
  documentID: string;
  auditID: string;
  selectedTab: string;
  filters: any;
}) => {
  const [pageNumber, setPageNumber] = useState<number>(1);

  const { data: documentStatus } = GetDocumentStatus(documentType, documentID);
  const { data: documentMetadata } = GetGRCDocumentMetadata(
    documentType,
    documentID
  );
  const { data: table, status: tableStatus } = GetInternalAuditSections(
    documentStatus?.status,
    auditID,
    selectedTab,
    pageNumber,
    filters
  );

  const documentName = documentMetadata?.framework_name;

  const sortedTable = table?.data;
  const totalCount = table?.pager?.total_results || 0;
  const totalPages = table?.pager?.num_pages || 0;
  const beginning = pageNumber === 1 ? 1 : pageSize * (pageNumber - 1) + 1;
  const end = pageNumber === totalPages ? totalCount : beginning + pageSize - 1;

  return (
    <section className="flex flex-col flex-grow mb-4 min-h-screen">
      {documentStatus?.status === "failed" ? (
        <section className="grid place-content-center gap-10 w-full h-full text-center">
          <img src="/errors/503.svg" alt="error" className="mx-auto h-72" />
          <h4>
            Oops! something went wrong! We will reach out to you shortly to help
            resolve the issue. Thanks for your patience.
          </h4>
        </section>
      ) : documentStatus?.status === "parsing" ? (
        <article className="flex items-center place-content-center gap-5">
          <img
            src={`/grc/frameworks-placeholder.svg`}
            alt="placeholder"
            className="w-40 h-40"
          />
          <article className="grid gap-3">
            <h4>
              Your document has been received and is currently being processed
            </h4>
            <img
              src="/grc/data-parsing.svg"
              alt="data parsing"
              className="w-10 h-10"
            />
          </article>
        </article>
      ) : tableStatus === "loading" ? (
        <Loader />
      ) : (
        tableStatus === "success" && (
          <section className="flex flex-col flex-grow content-start gap-3 min-h-full">
            {sortedTable ? (
              sortedTable.length > 0 ? (
                <section className="grid gap-5">
                  {sortedTable.map((section: any, sectionIndex: number) => {
                    let result = { pass: [], fail: [] } as any;
                    section.system_analysis?.findings?.forEach(
                      (finding: any) => {
                        finding.did_pass === true
                          ? result.pass.push(finding.summary)
                          : result.fail.push(finding.summary);
                      }
                    );
                    return (
                      <Disclosure key={sectionIndex}>
                        {({ open }) => (
                          <section className="grid gap-5 p-6 dark:bg-gray-800 border dark:border-gray-700 rounded-xl">
                            <Disclosure.Button className="flex items-center justify-between gap-10">
                              <h4 className="flex items-start gap-2 text-b1-reg text-left">
                                {section.section_id}{" "}
                                <span className="dark:text-gray-500">
                                  {!["-", "", null].includes(
                                    section.section_title
                                  ) && section.section_title}
                                </span>
                              </h4>
                              <article className="flex items-center gap-2 dark:text-blue-500">
                                <FontAwesomeIcon
                                  icon={open ? faChevronUp : faChevronDown}
                                />
                                <p>{open ? "Collapse" : "Expand"}</p>
                              </article>
                            </Disclosure.Button>
                            <Disclosure.Panel>
                              <section key={sectionIndex}>
                                {((selectedTab === "controls" &&
                                  section.system_analysis?.findings) ||
                                  selectedTab === "report") && (
                                  <header className="grid gap-3 pb-4 border-b dark:border-black">
                                    {section.system_analysis && result ? (
                                      <article className="grid gap-3">
                                        <h4>
                                          System Analysis{" "}
                                          {section.system_analysis.last_run &&
                                            `(last performed: ${convertToUTCString(
                                              section.system_analysis.last_run
                                            )})`}
                                        </h4>
                                        {Object.keys(result).map((status) => {
                                          if (result[status].length === 0)
                                            return null;
                                          return (
                                            <Disclosure key={status}>
                                              {({ open }) => (
                                                <>
                                                  <Disclosure.Button className="flex items-center gap-2 text-sm">
                                                    <span
                                                      className={`uppercase ${attributeColors[status]}`}
                                                    >
                                                      {result[status]?.length}{" "}
                                                      {status}
                                                    </span>
                                                    <FontAwesomeIcon
                                                      icon={
                                                        open
                                                          ? faChevronDown
                                                          : faChevronRight
                                                      }
                                                    />
                                                  </Disclosure.Button>
                                                  <Disclosure.Panel>
                                                    <ul className="flex flex-col flex-grow gap-3 p-2 max-h-[20rem] overflow-auto scrollbar">
                                                      {result[status]?.map(
                                                        (
                                                          summary: string,
                                                          index: number
                                                        ) => (
                                                          <li
                                                            key={index}
                                                            className={`flex items-center gap-2 px-4 py-1 ${
                                                              status === "pass"
                                                                ? "bg-black/60"
                                                                : "bg-reset"
                                                            } rounded-md`}
                                                          >
                                                            <FontAwesomeIcon
                                                              icon={
                                                                status ===
                                                                "pass"
                                                                  ? faCheck
                                                                  : faXmark
                                                              }
                                                              className={`${
                                                                status ===
                                                                "pass"
                                                                  ? "text-no"
                                                                  : ""
                                                              }`}
                                                            />
                                                            {summary}
                                                          </li>
                                                        )
                                                      )}
                                                    </ul>
                                                  </Disclosure.Panel>
                                                </>
                                              )}
                                            </Disclosure>
                                          );
                                        })}
                                      </article>
                                    ) : (
                                      selectedTab === "report" && (
                                        <SectionAnalysis section={section} />
                                      )
                                    )}
                                  </header>
                                )}
                                {section.sub_sections && (
                                  <article className="grid gap-5">
                                    {section.sub_sections.map(
                                      (
                                        control: any,
                                        subSectionIndex: number
                                      ) => {
                                        return (
                                          <ControlDetail
                                            key={subSectionIndex}
                                            documentType="internal-audit"
                                            documentID={documentID}
                                            documentName={documentName}
                                            auditID={auditID}
                                            control={control}
                                          />
                                        );
                                      }
                                    )}
                                  </article>
                                )}
                              </section>
                            </Disclosure.Panel>
                          </section>
                        )}
                      </Disclosure>
                    );
                  })}
                </section>
              ) : (
                <p>No controls found</p>
              )
            ) : null}
            <TablePagination
              totalPages={totalPages}
              beginning={beginning}
              end={end}
              totalCount={totalCount}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
            />
          </section>
        )
      )}
    </section>
  );
};

export default Sections;
