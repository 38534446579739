/* eslint-disable react-hooks/exhaustive-deps */
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChevronDownIcon } from "@heroicons/react/solid";
import React, { useState } from "react";
import { GetSOPTagNamesAndValues } from "src/services/business-continuity/sop";

const DepartmentFilter = ({
  label,
  selectedDepartment,
  setSelectedDepartment,
}: {
  label?: string;
  selectedDepartment: string[];
  setSelectedDepartment: (selectedDepartment: string[]) => void;
}) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [query, setQuery] = useState<string>("");

  const { data: sopTags } = GetSOPTagNamesAndValues();

  const filteredList = sopTags
    ? sopTags.filter((auth: string) =>
        auth
          .toLowerCase()
          .replace(/\s+/g, "")
          .includes(query.toLowerCase().replace(/\s+/g, ""))
      )
    : [];

  const handleSelect = (department: string) => {
    if (selectedDepartment.length > 0 && selectedDepartment[0] === department) {
      setSelectedDepartment([]);
      setQuery("");
    } else {
      setSelectedDepartment([department]);
      setQuery(department);
    }
  };

  return (
    <section
      className="flex items-center gap-3 text-b2-reg text-left"
      onMouseLeave={() => setShowDropdown(false)}
    >
      {label && <h4>{label}</h4>}
      <article
        onClick={() => setShowDropdown(true)}
        className="relative px-4 py-1 w-[25rem] dark:bg-gray-700 dark:hover:bg-gray-700/60 duration-100 focus:outline-none rounded-t-sm"
      >
        <article className="flex items-center justify-between gap-2">
          <input
            type="input"
            autoComplete="off"
            spellCheck="false"
            placeholder="Select"
            value={
              selectedDepartment.length > 0 ? selectedDepartment[0] : query
            }
            onChange={(e) => {
              if (!showDropdown) setShowDropdown(true);
              setQuery(e.target.value);
              if (
                selectedDepartment.length > 0 &&
                e.target.value !== selectedDepartment[0]
              )
                setSelectedDepartment([]);
            }}
            className="w-full border-transparent focus:ring-0 focus:border-transparent bg-transparent focus:outline-none"
          />
          <span className="flex items-center pointer-events-none">
            <ChevronDownIcon className="w-5 h-5" aria-hidden="true" />
          </span>
        </article>
        {showDropdown && (
          <article
            className={`absolute top-7 right-0 grid content-start w-full max-h-36 dark:bg-gray-700 focus:outline-none overflow-auto scrollbar rounded-b-sm`}
          >
            {sopTags ? (
              filteredList?.length > 0 ? (
                filteredList.map((department: string) => (
                  <button
                    key={department}
                    className="relative group flex items-center gap-1 px-4 py-1 text-left break-words cursor-pointer dark:hover:bg-gray-600/70 duration-100"
                    onClick={() => handleSelect(department)}
                  >
                    {selectedDepartment.length > 0 &&
                      selectedDepartment[0] === department && (
                        <FontAwesomeIcon icon={faCheck} className="text-no" />
                      )}
                    {department}
                  </button>
                ))
              ) : (
                <p className="px-4 py-1">No results found</p>
              )
            ) : null}
          </article>
        )}
      </article>
    </section>
  );
};

export default DepartmentFilter;
