import UnoLogo from "../components/General/UnoLogo";

const SessionLayout: React.FC = ({ children }) => {
  return (
    <section
      tabIndex={0}
      className="grid content-center w-screen min-h-screen text-center dark:text-white dark:bg-gray-900 overflow-auto scrollbar"
    >
      <section className="grid gap-5 p-20 w-3/5 lg:w-3/6 min-h-full mx-auto">
        <UnoLogo />
        {children}
      </section>
    </section>
  );
};

export default SessionLayout;
