/* eslint-disable react-hooks/exhaustive-deps */
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChevronDownIcon } from "@heroicons/react/solid";
import React, { useState } from "react";
import { userColors } from "src/constants/general";
import { GetAuditTrailUsers } from "src/services/regulation-policy/overview";
import { KeyStringVal } from "src/types/general";
import { getCustomerID } from "src/utils/general";

const AuditTrailUsersFilter = ({
  label,
  inputs,
  setInputs,
}: {
  label?: string;
  inputs: string[];
  setInputs: (inputs: string[]) => void;
}) => {
  const customerID = getCustomerID();

  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  const { data: auditTrailUsers } = GetAuditTrailUsers(customerID);

  return (
    <article className="flex items-center gap-3 text-b2-reg text-left">
      {label && <h4>{label}</h4>}
      <article
        onMouseLeave={() => setShowDropdown(false)}
        onClick={() => setShowDropdown(true)}
        className="relative px-4 py-1 w-[20rem] cursor-pointer dark:bg-gray-700 dark:hover:bg-gray-700/60 duration-100 focus:outline-none rounded-t-sm"
      >
        <article className="flex items-center justify-between gap-2">
          <input
            type="input"
            autoComplete="off"
            spellCheck="false"
            placeholder="Please select"
            value={`${!inputs.length ? "" : `${inputs.length} selected`}`}
            onChange={(e) => {
              if (!showDropdown) setShowDropdown(true);
            }}
            className="w-full border-transparent focus:ring-0 focus:border-transparent bg-transparent focus:outline-none"
          />
          <span className="flex items-center pointer-events-none">
            <ChevronDownIcon className="w-5 h-5" aria-hidden="true" />
          </span>
        </article>
        {showDropdown && (
          <article
            className={`absolute top-7 right-0 grid content-start w-full max-h-36 dark:bg-gray-700 focus:outline-none overflow-auto scrollbar rounded-b-sm`}
          >
            {auditTrailUsers ? (
              auditTrailUsers?.length > 0 ? (
                auditTrailUsers.map((user: KeyStringVal) => (
                  <button
                    key={user.user_id}
                    className="relative group flex gap-1 py-1 px-4 text-left break-words dark:hover:bg-gray-600/70 duration-100"
                    onClick={() => {
                      if (inputs.includes(user.user_id))
                        setInputs(
                          inputs.filter(
                            (userID: string) => userID !== user.user_id
                          )
                        );
                      else setInputs([...inputs, user.user_id]);
                    }}
                  >
                    {inputs.includes(user.user_id) && (
                      <FontAwesomeIcon icon={faCheck} className="text-no" />
                    )}
                    <article
                      key={user.user_id}
                      className="flex items-center gap-1 text-left"
                    >
                      <span
                        className={`grid content-center w-5 h-5 capitalize text-center text-[0.65rem] dark:text-white font-medium bg-gradient-to-b ${
                          userColors[user.user_email[0].toLowerCase()]
                        } shadow-sm dark:shadow-checkbox rounded-full`}
                      >
                        {user.user_email[0]}
                      </span>
                      <p>{user.user_email} </p>
                    </article>
                  </button>
                ))
              ) : (
                <p className="px-4 py-1">No results found</p>
              )
            ) : null}
          </article>
        )}
      </article>
    </article>
  );
};

export default AuditTrailUsersFilter;
