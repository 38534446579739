import {
  faDownload,
  faFileExport,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { LineWave } from "react-loader-spinner";
import {
  DownloadRedlining,
  ExportRedlining,
  GetRedliningExportStatus,
} from "../../../../services/grc";

const ExportFile = ({ documentID }: { documentID: string }) => {
  const exportRedlining = ExportRedlining(documentID);
  const { data: exportStatus } = GetRedliningExportStatus(documentID);
  const downloadRedlining = DownloadRedlining(documentID);

  const onDownload = () => {
    downloadRedlining.mutate(
      {},
      {
        onSuccess: (data) => {
          const url = data;
          let a = document.createElement("a");
          a.href = url;
          a.download = "redlining.docx";
          a.target = "_blank";
          a.click();
        },
      }
    );
  };

  return (
    <article className="place-self-end">
      {exportStatus?.status === "triggered" ? (
        <span className="flex items-center gap-1">
          Exporting{" "}
          <LineWave
            visible={true}
            height="30"
            width="30"
            color="#4fa94d"
            ariaLabel="line-wave-loading"
            wrapperStyle={{}}
            wrapperClass=""
            firstLineColor=""
            middleLineColor=""
            lastLineColor=""
          />
        </span>
      ) : exportStatus?.status === "ready" ? (
        <button
          className="flex items-center gap-1 px-4 py-1 dark:bg-blue-600 dark:hover:bg-blue-600/70 duration-100 rounded-md"
          onClick={onDownload}
        >
          <FontAwesomeIcon icon={faDownload} className="w-4 h-4" />
          Download as File
        </button>
      ) : exportStatus?.status === "failed" ? (
        <span>
          <FontAwesomeIcon
            icon={faTriangleExclamation}
            className="text-reset"
          />{" "}
          Export failed
        </span>
      ) : (
        <button
          className="flex items-center gap-1 text-left dark:hover:text-checkbox/60 duration-100"
          onClick={() => {
            exportRedlining.mutate({});
          }}
        >
          <FontAwesomeIcon icon={faFileExport} className="text-checkbox" />
          Export
        </button>
      )}
    </article>
  );
};

export default ExportFile;
