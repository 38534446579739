/* eslint-disable react-hooks/exhaustive-deps */
import { faArrowLeftLong, faSort } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import {
  GetQuestionSetsFromGroup,
  GetVendorGroupMetadata,
  GetVendorsFromGroup,
} from "src/services/third-party-risk/vendors/vendor-groups";
import { KeyStringVal } from "src/types/general";
import VendorsFilter from "src/components/Filter/ThirdPartyRisk/VendorsFilter";
import Loader from "src/components/Loader/Loader";
import { useNavigate } from "react-router-dom";
import TablePagination from "src/components/General/TablePagination";
import { initialSort, pageSize, attributeColors } from "src/constants/general";
import TableLayout from "src/layouts/TableLayout";
import { Sort } from "src/types/dashboard";
import { sortRows, handleSort, lastUpdatedAt } from "src/utils/general";
import AddQuestionSet from "./AddQuestionSet";

const VendorGroupDetail = ({
  selectedVendorGroup,
  setSelectedPolicyGroup,
}: {
  selectedVendorGroup: KeyStringVal;
  setSelectedPolicyGroup: (selectedVendorGroup: KeyStringVal) => void;
}) => {
  const navigate = useNavigate();

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [sort, setSort] = useState<Sort>(initialSort);

  const groupID = sessionStorage.vendor_group_id;

  const { data: vendorGroupMetadata } = GetVendorGroupMetadata(groupID);
  const {
    data: vendors,
    status: vendorsStatus,
    refetch,
  } = GetVendorsFromGroup(groupID, pageNumber);
  const { data: questionSets } = GetQuestionSetsFromGroup(groupID);

  const metadata = vendors && JSON.parse(vendors.header.metadata);
  const filteredMetadata = metadata?.required.filter((col: string) => {
    if (metadata.properties[col].hidden === "True") return null;
    return col;
  });
  const sortedRows = vendors && sortRows(vendors?.data, sort);

  const totalCount = vendors?.pager.total_results || 0;
  const totalPages = Math.ceil(totalCount / pageSize);
  const beginning =
    pageNumber === 1 ? 1 : pageSize * ((pageNumber || 1) - 1) + 1;
  const end = pageNumber === totalPages ? totalCount : beginning + pageSize - 1;

  const handleReturn = () => {
    sessionStorage.removeItem("vendor_group_id");
    setSelectedPolicyGroup({});
  };

  useEffect(() => {
    refetch();
  }, [vendors]);

  return (
    <section className="flex flex-col flex-grow gap-5">
      {vendorGroupMetadata && (
        <header className="flex items-center gap-10">
          <article className="flex items-center gap-5">
            <button
              className="flex gap-2 items-center w-max tracking-wide text-sm dark:text-checkbox dark:hover:text-checkbox/50 duration-100"
              onClick={handleReturn}
            >
              <FontAwesomeIcon icon={faArrowLeftLong} />
              <span>Return</span>
            </button>
            <h4 className="text-xl">{vendorGroupMetadata.name}</h4>
          </article>
          <VendorsFilter
            label="Add vendor"
            members={vendors?.data}
            groupID={groupID}
          />
        </header>
      )}
      {questionSets?.length > 0 && <AddQuestionSet groupID={groupID} />}
      {vendorsStatus === "loading" ? (
        <Loader />
      ) : vendors?.data.length > 0 ? (
        <section className="flex flex-col flex-grow gap-5 pb-20">
          <TableLayout fullHeight>
            <thead className="sticky -top-1.5 dark:bg-gray-900">
              <tr>
                {filteredMetadata?.map((col: string) => {
                  const column = metadata.properties[col];
                  return (
                    <th
                      scope="col"
                      key={col}
                      className="px-6 py-3 last:pr-10 w-full text-left font-semibold"
                    >
                      <article className="capitalize flex gap-10 justify-between">
                        <h4 className="break-words">{column.title}</h4>
                        <button aria-label="sort">
                          <FontAwesomeIcon
                            icon={faSort}
                            className="mt-0.5 dark:text-gray-700"
                            onClick={() => handleSort(col, setSort)}
                          />
                        </button>
                      </article>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody className="divide-y dark:divide-gray-700">
              {sortedRows?.map((row: any, index: number) => {
                return (
                  <tr
                    key={index}
                    data-test="table-row"
                    className="relative p-5 gap-3 cursor-pointer break-words dark:bg-gray-800 dark:hover:bg-gray-800/70 duration-100"
                    onClick={() => {
                      sessionStorage.GRCCategory = "vendors";
                      navigate(
                        `/third-party-risk/vendors/details?vendor_id=${row.third_party_id}`
                      );
                    }}
                  >
                    {filteredMetadata?.map((col: string, colIndex: number) => {
                      const date = lastUpdatedAt(row.last_updated);

                      return (
                        <td
                          key={`${index}-${colIndex}`}
                          className="relative px-6 py-3 last:pr-16 text-left break-words"
                        >
                          <p
                            className={`${
                              attributeColors[String(row[col]).toLowerCase()]
                            }`}
                          >
                            {col === "last_updated" ? date : String(row[col])}
                          </p>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </TableLayout>
          <TablePagination
            totalPages={totalPages}
            beginning={beginning}
            end={end}
            totalCount={totalCount}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
        </section>
      ) : (
        <section className="flex items-center place-content-center gap-10 w-full h-full">
          <img
            src="/grc/third-party-risk-placeholder.svg"
            alt="vendors placeholder"
            className="w-40 h-40"
          />
          <article className="grid gap-3">
            <h4 className="text-xl font-extrabold">Vendors</h4>
            <h4>No vendors available</h4>
          </article>
        </section>
      )}
    </section>
  );
};

export default VendorGroupDetail;
