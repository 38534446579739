/* eslint-disable react-hooks/exhaustive-deps */
import {
  faRefresh,
  faXmark,
  faMagnifyingGlass,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
  Fragment,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import TablePagination from "src/components/General/TablePagination";
import Loader from "src/components/Loader/Loader";
import { KeyStringVal } from "src/types/general";
import { GetQnAList } from "src/services/questionnaire-rfp";
import { pageSize } from "src/constants/general";
import { useGRCStore } from "src/stores/grc";
import Response from "./Response";
import ExportFile from "./ExportFile";
import { checkSuperAdmin } from "src/utils/general";
import CreateTemplate from "./CreateTemplate";
import {
  AddQAPair,
  GetQuestionBanks,
  ResubmitQuestion,
} from "../../../../../services/questionnaire-rfp";
import { Popover, Transition } from "@headlessui/react";
import { attributeColors } from "src/constants/general";

const ResponseList = ({ assessmentID }: { assessmentID: string }) => {
  const isSuperAdmin = checkSuperAdmin();
  const sourceType = sessionStorage.source_type || "";

  const { GRCQuestionIDNotif } = useGRCStore();

  const [historyQuery, setHistoryQuery] = useState<string>("");
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [selectedQuestions, setSelectedQuestions] = useState<any>([]);

  const {
    data: qnaList,
    refetch,
    isFetching,
  } = GetQnAList(assessmentID, pageNumber, historyQuery);
  const { data: questionBanks } = GetQuestionBanks(1);
  const addQAPair = AddQAPair();
  const resubmitQuestion = ResubmitQuestion(assessmentID, sourceType);

  const totalCount = qnaList?.pager.total_results || 0;
  const totalPages = qnaList?.pager.num_pages || 0;
  const beginning = pageNumber === 1 ? 1 : pageSize * (pageNumber - 1) + 1;
  const end = pageNumber === totalPages ? totalCount : beginning + pageSize - 1;

  const questionRef = useRef(
    Array(qnaList?.data.length).fill(null)
  ) as MutableRefObject<any[]>;

  const handleSelectAllQuestions = () => {
    const questions = qnaList?.data?.reduce(
      (pV: string[], cV: KeyStringVal) => [
        ...pV,
        { question_id: cV.question_id },
      ],
      []
    );
    setSelectedQuestions(questions);
  };

  useEffect(() => {
    if (qnaList?.data.length > 0) {
      const questionIndex = qnaList?.data.findIndex(
        (question: KeyStringVal) => question.question_id === GRCQuestionIDNotif
      );
      if (questionRef?.current && questionRef?.current[questionIndex])
        questionRef.current[questionIndex].scrollIntoView();
    }
  }, [qnaList, GRCQuestionIDNotif]);

  useEffect(() => {
    if (qnaList?.data) handleSelectAllQuestions();
  }, [qnaList?.data]);

  useEffect(() => {
    if (historyQuery.length >= 3 || historyQuery.length === 0) refetch();
  }, [historyQuery]);

  return (
    <section className="grid content-start gap-5 w-full h-full">
      <header className="flex items-center gap-8 pb-2 border-b-1 dark:border-blue-600">
        <h4 className="text-2xl">Responses</h4>
        <button
          className="flex items-center gap-1 dark:hover:text-no/60 duration-100"
          onClick={() => refetch()}
        >
          <FontAwesomeIcon icon={faRefresh} className="text-no" />
          Refresh
        </button>
        <article className="flex items-center gap-5 text-b2-reg">
          <p>
            Search Documents
            <span
              className={`capitalize ${
                attributeColors[sessionStorage.enable_sources]
              }`}
            >
              {sessionStorage.enable_sources}
            </span>{" "}
          </p>
          <p>
            Search Question Banks
            <span
              className={`capitalize ${
                attributeColors[sessionStorage.enable_questionbank]
              }`}
            >
              {sessionStorage.enable_questionbank}
            </span>
          </p>
        </article>
      </header>
      <article className="flex items-start justify-between gap-10">
        <article className="flex items-center gap-5 pr-5 w-3/5 dark:bg-transparent border-b dark:border-know/6 know">
          <input
            type="input"
            spellCheck="false"
            autoComplete="off"
            placeholder="Search any question"
            value={historyQuery}
            onChange={(e) => setHistoryQuery(e.target.value)}
            className="p-3 w-full focus:outline-none dark:placeholder:text-checkbox dark:bg-transparent border-b dark:border-know/6 know dark:focus:ring-0 dark:focus:border-transparent"
          />
          <article className="flex items-center gap-2 divide-x-1 dark:divide-checkbox">
            {historyQuery !== "" && (
              <button onClick={() => setHistoryQuery("")}>
                <FontAwesomeIcon
                  icon={faXmark}
                  className="text-reset hover:text-reset/60 duration-100"
                />
              </button>
            )}
            <button
              disabled={historyQuery === ""}
              className="pl-2 dark:disabled:text-filter dark:text-blue-500 dark:hover:text-blue-500/60 duration-100"
              onClick={() => refetch()}
            >
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </button>
          </article>
        </article>
        <TablePagination
          totalPages={totalPages}
          beginning={beginning}
          end={end}
          totalCount={totalCount}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
        />
      </article>
      {isSuperAdmin && (
        <CreateTemplate qnaList={qnaList} assessmentID={assessmentID} />
      )}
      {isFetching ? (
        <Loader />
      ) : (
        qnaList?.data.length > 0 && (
          <section className="grid gap-3">
            <article className="flex items-center gap-5 divide-x dark:divide-checkbox">
              <button
                disabled={selectedQuestions.length === qnaList?.data?.length}
                className="dark:disabled:text-filter dark:hover:text-checkbox/60 duration-100"
                onClick={handleSelectAllQuestions}
              >
                Select All
              </button>
              <button
                disabled={selectedQuestions.length === 0}
                className="pl-5 dark:disabled:text-filter dark:hover:text-checkbox/60 duration-100"
                onClick={() => setSelectedQuestions([])}
              >
                Deselect All
              </button>
            </article>
            {selectedQuestions.length > 0 && (
              <section className="flex items-center gap-2">
                {sessionStorage.source_type === "policies" && (
                  <ExportFile
                    assessmentID={assessmentID}
                    selectedQuestions={selectedQuestions}
                  />
                )}
                <Popover className="relative">
                  <Popover.Button className="blue-button">
                    <FontAwesomeIcon icon={faPlus} className="w-3 h-3" />
                    Add To
                  </Popover.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute left-28 top-0 z-10">
                      {({ close }) => (
                        <section className="grid gap-1 p-4 w-[30rem] dark:bg-gray-700 black-shadow rounded-md">
                          <h4>Question Banks</h4>
                          <ul className="grid gap-3">
                            {questionBanks?.data.map(
                              (questionBank: KeyStringVal) => {
                                return (
                                  <li
                                    key={questionBank.document_id}
                                    className="px-3 py-1 cursor-pointer dark:bg-gray-900 dark:hover:bg-gray-900/70 duration-100 rounded-md"
                                    onClick={() => {
                                      selectedQuestions.forEach((question) =>
                                        addQAPair.mutate({
                                          questionBankID:
                                            questionBank.document_id,
                                          question: question.question,
                                          answer: question.answer,
                                        })
                                      );
                                      close();
                                    }}
                                  >
                                    {questionBank.name}
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </section>
                      )}
                    </Popover.Panel>
                  </Transition>
                </Popover>
                <button
                  className="green-button"
                  onClick={() => {
                    selectedQuestions.forEach((question) =>
                      resubmitQuestion.mutate({
                        questionID: question.question_id,
                      })
                    );
                  }}
                >
                  <FontAwesomeIcon icon={faMagnifyingGlass} /> Resubmit
                </button>
              </section>
            )}
            <ul className="flex flex-col flex-grow gap-5 pb-20">
              {qnaList?.data.map((question: any, questionIndex: number) => {
                return (
                  <Response
                    key={questionIndex}
                    assessmentID={assessmentID}
                    questionIndex={questionIndex}
                    questionRef={questionRef}
                    question={question}
                    selectedQuestions={selectedQuestions}
                    setSelectedQuestions={setSelectedQuestions}
                  />
                );
              })}
            </ul>
          </section>
        )
      )}
    </section>
  );
};

export default ResponseList;
