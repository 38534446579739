/* eslint-disable no-restricted-globals */
import { faXmark, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu, Transition } from "@headlessui/react";
import React from "react";
import { Fragment } from "react";
import { KeyStringVal } from "src/types/general";
import {
  AssignTagToVendor,
  GetVendorTags,
  RemoveTagFromVendor,
} from "../../../../services/third-party-risk/vendors/vendors";

const VendorTags = ({ vendorID, tags }: { vendorID: string; tags: any }) => {
  const { data: allTags } = GetVendorTags();
  const addTag = AssignTagToVendor(vendorID);
  const deleteTag = RemoveTagFromVendor(vendorID);

  const filteredTags = allTags?.filter(
    (tag: KeyStringVal) => !tags?.includes(tag.tag_name)
  );

  return (
    <section className="flex flex-wrap items-center gap-4 text-b1-reg">
      {tags?.length > 0 && (
        <ul className="flex flex-wrap items-center gap-3">
          {tags.map((tag: string) => {
            return (
              <li
                key={tag}
                className="flex items-center gap-3 px-4 py-1 dark:bg-gray-700 rounded"
              >
                <p>{tag}</p>

                {/* remove tag */}
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteTag.mutate({
                      tagName: tag,
                    });
                  }}
                >
                  <FontAwesomeIcon
                    icon={faXmark}
                    className="w-4 h-4 dark:hover:text-gray-500 duration-100 rounded"
                  />
                </button>
              </li>
            );
          })}
        </ul>
      )}

      <Menu as="article" className="relative inline-block text-left">
        <Menu.Button
          className="flex items-center gap-2 px-2 py-1 dark:bg-blue-900 dark:hover:bg-blue-900/70 duration-100 rounded"
          onClick={(e) => e.stopPropagation()}
        >
          <FontAwesomeIcon icon={faPlus} />
          <h4>Assign Tag</h4>
        </Menu.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute left-0 bottom-10 grid w-max max-h-[10rem] gap-2 origin-top-right focus:outline-none dark:text-white dark:bg-blue-900 rounded">
            {filteredTags?.length > 0 ? (
              <nav className="grid content-start w-full h-full overflow-auto scrollbar">
                {filteredTags?.map((tag: KeyStringVal) => {
                  return (
                    <button
                      key={tag.tag_id}
                      className="flex items-center gap-2 px-4 py-2 w-full text-left dark:hover:bg-blue-500/70 duration-100 rounded"
                      onClick={(e) => {
                        e.stopPropagation();
                        addTag.mutate({
                          tagName: tag.tag_name,
                        });
                      }}
                    >
                      <p>{tag.tag_name}</p>
                    </button>
                  );
                })}
              </nav>
            ) : (
              <section className="grid gap-2 px-5 py-3 w-max origin-top-right focus:outline-none dark:text-white dark:bg-blue-900 rounded">
                <h4>No new tags</h4>
              </section>
            )}
          </Menu.Items>
        </Transition>
      </Menu>
    </section>
  );
};

export default VendorTags;
