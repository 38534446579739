/* eslint-disable react-hooks/exhaustive-deps */
import { ChevronDownIcon } from "@heroicons/react/solid";
import React, { useState } from "react";
import { GetRegulatoryAuthorities } from "src/services/regulation-policy/framework";
import { KeyStringVal } from "src/types/general";

const RegAuthFilter = ({
  label,
  inputs,
  setInputs,
  isFramework,
}: {
  label?: string;
  inputs: any;
  setInputs: (inputs: KeyStringVal) => void;
  isFramework?: boolean;
}) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [query, setQuery] = useState<string>("");

  const { data: regAuth } = GetRegulatoryAuthorities(
    isFramework ? "framework" : "circular"
  );

  const filteredRegAuths = regAuth
    ? ["All", ...regAuth].filter((auth: string) =>
        auth
          .toLowerCase()
          .replace(/\s+/g, "")
          .includes(query.toLowerCase().replace(/\s+/g, ""))
      )
    : [];

  const handleSelect = (auth: string) => {
    setInputs({ ...inputs, regulatory_authority: auth });
    setQuery(auth);
  };

  return (
    <section
      className="flex items-center gap-3 text-b2-reg text-left"
      onMouseLeave={() => setShowDropdown(false)}
    >
      {label && <h4>{label}</h4>}
      <article
        onClick={() => setShowDropdown(true)}
        className="relative px-4 py-1 dark:bg-gray-700 dark:hover:bg-gray-700/60 duration-100 focus:outline-none rounded-sm"
      >
        <article className="flex items-center justify-between gap-2">
          <input
            type="input"
            autoComplete="off"
            spellCheck="false"
            placeholder="Select"
            value={query}
            onChange={(e) => {
              if (!showDropdown) setShowDropdown(true);
              setQuery(e.target.value);
            }}
            className="w-full border-transparent focus:ring-0 focus:border-transparent bg-transparent focus:outline-none"
          />
          <span className="flex items-center pointer-events-none">
            <ChevronDownIcon className="w-5 h-5" aria-hidden="true" />
          </span>
        </article>
        {showDropdown && (
          <article className="absolute top-8 right-0 grid content-start w-full max-h-36 dark:bg-gray-700 focus:outline-none overflow-auto scrollbar rounded-b-sm z-10">
            {regAuth ? (
              filteredRegAuths?.length > 0 ? (
                filteredRegAuths?.map((auth: string) => (
                  <button
                    key={auth}
                    className="relative group flex gap-1 py-1 px-4 text-left break-words cursor-pointer dark:hover:bg-gray-600/70 duration-100"
                    onClick={() => handleSelect(auth)}
                  >
                    {auth}
                  </button>
                ))
              ) : (
                <p className="px-4 py-1">No results found</p>
              )
            ) : null}
          </article>
        )}
      </article>
    </section>
  );
};

export default RegAuthFilter;
