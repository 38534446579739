import React from "react";
import SortColumn from "src/components/Button/SortColumn";
import { ListHeader, SortRows } from "../types/general";

const ListLayout: React.FC<{
  listHeader: ListHeader[];
  setSort?: (sort: SortRows) => void;
  height?: string;
  excludeSortCols?: string[];
  hideSticky?: boolean;
}> = ({
  height,
  listHeader,
  setSort,
  excludeSortCols,
  hideSticky,
  children,
}) => {
  return (
    <section
      className={`flex flex-col flex-grow w-full ${
        height ? height : "max-h-96"
      } text-b1-reg overflow-x-auto scrollbar border dark:border-gray-700 rounded-lg`}
    >
      <table className="w-full h-full table-fixed text-left rounded-lg">
        <thead
          className={`${!hideSticky ? "sticky -top-1.5" : ""} dark:bg-gray-900`}
        >
          <tr>
            {listHeader?.map((label: ListHeader) => (
              <th
                key={label.property_name}
                className="px-6 py-3 last:pr-10 w-full text-left font-semibold"
              >
                <article className="capitalize flex gap-10 justify-between">
                  <h4>{label.display_name}</h4>
                  {setSort &&
                    !excludeSortCols?.includes(label.property_name) && (
                      <SortColumn
                        propertyName={label.property_name}
                        setSort={setSort}
                      />
                    )}
                </article>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="divide-y dark:divide-gray-700">{children}</tbody>
      </table>
    </section>
  );
};

export default ListLayout;
