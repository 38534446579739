import React from "react";
import { KeyStringVal } from "src/types/general";
import Source from "../../../../QuestionnaireRFP/Assessments/AssessmentDetail/ResponseList/Source";

const ControlContent = ({ subsection }: { subsection: any }) => {
  return (
    <section className="grid gap-5 text-b1-reg">
      <p className="grid gap-2 w-full dark:text-gray-400">
        {subsection.content.split("\n").map((phrase: string, index: number) => (
          <span
            key={index}
            className="flex flex-wrap items-center gap-1 w-full"
          >
            {phrase.split(" ").map((word, wordIndex) => (
              <span
                key={wordIndex}
                className={`${
                  subsection.search_highlight?.matched_tokens?.includes(word)
                    ? "text-black bg-yellow-300"
                    : ""
                }`}
              >
                {word}
              </span>
            ))}
          </span>
        ))}
      </p>

      {subsection.mapped_policy_section_objects?.length > 0 && (
        <section className="grid gap-2">
          <h4 className="flex items-center gap-1">
            {subsection.mapped_policy_section_objects.length} Mapped Policy
            Sections
          </h4>
          <section className="flex flex-wrap items-center gap-5">
            {subsection.mapped_policy_section_objects?.map(
              (source: KeyStringVal, sourceIndex: number) => {
                return (
                  <Source
                    key={sourceIndex}
                    sourceIndex={sourceIndex}
                    source={source}
                  />
                );
              }
            )}
          </section>
        </section>
      )}
    </section>
  );
};

export default ControlContent;
