/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import ErrorLayout from "../../layouts/ErrorLayout";
import { useGeneralStore } from "../../stores/general";

const Forbidden: React.FC = () => {
  const { error } = useGeneralStore();

  return (
    <ErrorLayout
      errorCode="403"
      message={error.message || "access forbidden"}
    />
  );
};

export default Forbidden;
