import {
  faChevronDown,
  faChevronRight,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Disclosure } from "@headlessui/react";
import React, { useState } from "react";
import FileInput from "src/components/Input/FileInput";
import RegularInput from "src/components/Input/RegularInput";
import { AddControlEvidence } from "src/services/audit-management";

const AttachEvidence = ({
  auditID,
  controlID,
}: {
  auditID: string;
  controlID: string;
}) => {
  const [inputs, setInputs] = useState<any>({
    name: "",
    file: "",
  });

  const attachEvidence = AddControlEvidence(auditID, controlID);

  return (
    <>
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className="flex items-center gap-2">
              <p>Add evidence</p>
              <FontAwesomeIcon icon={open ? faChevronDown : faChevronRight} />
            </Disclosure.Button>
            <Disclosure.Panel>
              <section className="grid gap-5">
                <RegularInput
                  label="Label"
                  keyName="name"
                  inputs={inputs}
                  setInputs={setInputs}
                  required
                />
                <FileInput
                  label="Evidence"
                  keyName="file"
                  types={[
                    "png",
                    "svg",
                    "pdf",
                    "docx",
                    "csv",
                    "txt",
                    "xlsx",
                    "jpg",
                    "jpeg",
                  ]}
                  inputs={inputs}
                  setInputs={setInputs}
                />
                <article className="flex items-center place-content-end gap-5">
                  <button className="black-button">Cancel</button>
                  <button
                    disabled={inputs.name === "" || inputs.file === ""}
                    className="blue-button"
                    onClick={() => {
                      const formData = new FormData();

                      formData.append("name", inputs.name);
                      formData.append("file", inputs.file);

                      attachEvidence.mutate({
                        formData: formData,
                      });
                    }}
                  >
                    Upload
                  </button>
                </article>
              </section>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  );
};

export default AttachEvidence;
