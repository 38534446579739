import React, { useState } from "react";
import ModalLayout from "src/layouts/ModalLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong, faUpload } from "@fortawesome/free-solid-svg-icons";
import SelectFrameworkFilter from "src/components/Filter/RegulationPolicy/SelectFrameworkFilter";
import { GetFrameworksWithControls } from "src/services/regulation-policy/framework";
import { useNavigate } from "react-router-dom";
import { AddAudit } from "src/services/audit-management";
import RegularInput from "src/components/Input/RegularInput";

const NewAudit = () => {
  const navigate = useNavigate();

  const [show, setShow] = useState<boolean>(false);
  const [inputs, setInputs] = useState({
    name: "",
  });
  const [selectedFramework, setSelectedFramework] = useState<any>({
    id: "",
    name: "",
  });

  const { data: frameworksWithControls } = GetFrameworksWithControls();
  const addAudit = AddAudit();

  const handleOnClose = () => setShow(false);
  const handleOnCloseConfirmation = () => addAudit.reset();

  return (
    <>
      <button
        className="place-self-end blue-button"
        onClick={() => {
          setShow(true);
          setInputs({
            name: "",
          });
          setSelectedFramework({ id: "", name: "" });
        }}
      >
        <FontAwesomeIcon icon={faUpload} />
        <h4>Start Internal Audit</h4>
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <>
          {addAudit.data !== undefined ? (
            <section className="grid content-start gap-10 h-full">
              <article className="flex items-center gap-2">
                <img
                  src="/general/checkmark.svg"
                  alt="checkmark"
                  className="w-5 h-5"
                />
                <h3 className="text-subt1-semi">
                  New Internal Audit has been created!
                </h3>
              </article>
              <article className="flex items-center gap-2">
                <h4>
                  Go to Internal Audit
                  <button
                    className="p-1 dark:hover:bg-gray-700/70 duration-100 rounded-full"
                    onClick={() => {
                      navigate(
                        `/audit-management/audit/details?audit_id=${addAudit.data.audit_id}&framework_id=${selectedFramework.id}`
                      );
                      handleOnCloseConfirmation();
                      handleOnClose();
                    }}
                  >
                    <FontAwesomeIcon icon={faArrowRightLong} />
                  </button>
                </h4>
              </article>
            </section>
          ) : (
            <section className="grid content-start gap-5 h-[20rem]">
              <h3 className="flex items-center gap-2 text-t1-semi">
                Start Internal Audit
              </h3>
              <RegularInput
                label="Name"
                keyName="name"
                inputs={inputs}
                setInputs={setInputs}
                required
              />
              <article className="grid gap-2">
                <h4>Framework</h4>
                <SelectFrameworkFilter
                  selectedFramework={selectedFramework}
                  setSelectedFramework={setSelectedFramework}
                  list={frameworksWithControls?.data}
                />
              </article>
              <article className="flex items-center place-content-end gap-5">
                <button className="black-button" onClick={handleOnClose}>
                  Cancel
                </button>
                <button
                  disabled={selectedFramework.id === "" || inputs.name === ""}
                  className="blue-button"
                  onClick={() => {
                    addAudit.mutate({
                      name: inputs.name,
                      framework_id: selectedFramework.id,
                    });
                    handleOnClose();
                  }}
                >
                  <h4>Done</h4>
                </button>
              </article>
            </section>
          )}
        </>
      </ModalLayout>
    </>
  );
};

export default NewAudit;
