/* eslint-disable no-restricted-globals */
import DropdownLayout from "../../../layouts/DropdownLayout";
import { getCustomerID } from "../../../utils/general";
import { DeleteGroup } from "src/services/settings/groups";

const GroupActionFilter = ({
  list,
  multiSelect,
  setMultiSelect,
}: {
  list: string[];
  multiSelect: string[];
  setMultiSelect: (multiSelect: string[]) => void;
}) => {
  const customerID = getCustomerID();

  const deleteGroup = DeleteGroup(customerID);

  return (
    <DropdownLayout value="">
      {list?.map((item: string, index: number) => (
        <button
          key={index}
          className="relative px-4 py-1 text-left break-all dark:hover:bg-gray-600/70 duration-100"
          onClick={() => {
            setMultiSelect([]);
            multiSelect.map((groupID) =>
              deleteGroup.mutate({
                groupID: groupID,
              })
            );
          }}
        >
          <p>{item}</p>
        </button>
      ))}
    </DropdownLayout>
  );
};

export default GroupActionFilter;
