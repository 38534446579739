import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import {
  MDXEditor,
  toolbarPlugin,
  headingsPlugin,
  listsPlugin,
  quotePlugin,
  thematicBreakPlugin,
  markdownShortcutPlugin,
  tablePlugin,
  BoldItalicUnderlineToggles,
  UndoRedo,
  BlockTypeSelect,
  CodeToggle,
  CreateLink,
  DiffSourceToggleWrapper,
  InsertCodeBlock,
  InsertFrontmatter,
  InsertImage,
  InsertTable,
  InsertThematicBreak,
  ListsToggle,
  imagePlugin,
  frontmatterPlugin,
  diffSourcePlugin,
  linkDialogPlugin,
  StrikeThroughSupSubToggles,
  codeBlockPlugin,
  codeMirrorPlugin,
} from "@mdxeditor/editor";
import "@mdxeditor/editor/style.css";
import {
  GetGeneratedPolicyMarkdown,
  UpdateGeneratedPolicy,
} from "../../../../services/regulation-policy/policy";

const RawDocument = ({ documentID }: { documentID: string }) => {
  const [editedContent, setEditedContent] = useState<string>("");

  const { data: markdown } = GetGeneratedPolicyMarkdown(documentID);
  const updateGeneratedPolicy = UpdateGeneratedPolicy(documentID);

  const content = markdown?.content;

  return (
    <section className="flex flex-col flex-grow gap-2 p-2 w-full h-screen dark:text-black dark:bg-white rounded-md">
      {editedContent ? (
        <>
          <article className="flex items-center place-content-end gap-5">
            <button
              className="black-button"
              onClick={() => setEditedContent("")}
            >
              Discard
            </button>
            {content !== editedContent && (
              <button
                className="blue-button"
                onClick={() => {
                  updateGeneratedPolicy.mutate({
                    editedContent,
                  });
                  setEditedContent("");
                }}
              >
                Save
              </button>
            )}
          </article>

          <MDXEditor
            className="min-w-full min-h-full prose overflow-auto"
            markdown={content}
            plugins={[
              toolbarPlugin({
                toolbarContents: () => (
                  <>
                    <UndoRedo />
                    <BoldItalicUnderlineToggles />
                    <StrikeThroughSupSubToggles />
                    <BlockTypeSelect />
                    <CodeToggle />
                    <CreateLink />
                    <InsertCodeBlock />
                    <InsertFrontmatter />
                    <InsertImage />
                    <InsertTable />
                    <InsertThematicBreak />
                    <ListsToggle />
                    <DiffSourceToggleWrapper />
                  </>
                ),
              }),
              headingsPlugin(),
              listsPlugin(),
              quotePlugin(),
              thematicBreakPlugin(),
              markdownShortcutPlugin(),
              tablePlugin(),
              imagePlugin(),
              frontmatterPlugin(),
              diffSourcePlugin({
                diffMarkdown: content,
                viewMode: "rich-text",
                readOnlyDiff: true,
              }),
              linkDialogPlugin(),
              codeBlockPlugin(),
              codeMirrorPlugin({
                codeBlockLanguages: {
                  js: "JavaScript",
                  py: "Python",
                  "": "Text",
                },
              }),
            ]}
            onChange={(content) => setEditedContent(content)}
          />
        </>
      ) : (
        <>
          {!editedContent && (
            <button
              className="sticky top-0 place-self-end px-2 py-1 w-max text-white dark:bg-black dark:hover:bg-black/60 duration-100 rounded-md"
              onClick={() => setEditedContent(content)}
            >
              Edit
            </button>
          )}
          <ReactMarkdown
            className="min-w-full prose dark:bg-white overflow-auto rounded-md"
            remarkPlugins={[remarkGfm]}
          >
            {content}
          </ReactMarkdown>
        </>
      )}
    </section>
  );
};

export default RawDocument;
