import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popover, Transition } from "@headlessui/react";
import React, { Fragment } from "react";

const SectionTabs = ({
  documentType,
  selectedTab,
  filter,
  setFilter,
  setAddSectionsToPolicy,
  setSelectedAddedSections,
}: {
  documentType: string;
  selectedTab: string;
  filter: string;
  setFilter: (filter: string) => void;
  setAddSectionsToPolicy?: any;
  setSelectedAddedSections?: any;
}) => {
  const isPolicy = documentType === "policies";

  return (
    <Popover className="relative text-b2-reg">
      <Popover.Button>
        <article className="flex items-center gap-2 px-4 py-2 dark:bg-gray-900 border dark:border-gray-700 divide-x dark:divide-gray-700 rounded">
          <h4>Quick Filter</h4>
          <FontAwesomeIcon
            icon={faFilter}
            className="pl-2 dark:text-blue-600"
          />
        </article>
      </Popover.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="absolute left-0 top-12 z-10">
          {({ close }) => (
            <section className="grid gap-1 p-4 w-full dark:bg-gray-700 black-shadow rounded-lg">
              <ul className="flex flex-col flex-grow gap-1">
                {[
                  "All",
                  "Mapped To Policy",
                  "Relevant Sections",
                  `Overlaps With Framework`,
                  "Only Mapping",
                  `No Mapping${isPolicy ? "" : " with Policy"}`,
                  "Suggested Content",
                ].map((option) => {
                  if (
                    (selectedTab === "Sections" &&
                      option === "Relevant Sections") ||
                    (!isPolicy &&
                      ["Only Mapping", "Suggested Content"].includes(option)) ||
                    (isPolicy &&
                      [
                        "Mapped To Policy",
                        "Relevant Sections",
                        "Overlaps With Framework",
                      ].includes(option))
                  )
                    return null;
                  return (
                    <li key={option} className="flex items-center gap-2 w-full">
                      <input
                        type="radio"
                        className="form-radio w-4 h-4 dark:bg-transparent dark:ring-0 dark:text-blue-500 dark:focus:border-blue-600 focus:ring dark:focus:ring-offset-0 dark:focus:ring-blue-600 focus:ring-opacity-50 rounded-full"
                        checked={filter === option}
                        onClick={() => {
                          let filteredOption = "";
                          if (option === "Suggested Content") {
                            filteredOption = "Suggest New Mapping";
                            setFilter("Suggest New Mapping");
                          } else {
                            filteredOption = option;
                            setFilter(option);
                          }
                          sessionStorage.section_tab = filteredOption;
                          if (
                            setAddSectionsToPolicy &&
                            setSelectedAddedSections
                          ) {
                            setAddSectionsToPolicy(false);
                            setSelectedAddedSections([]);
                          }
                        }}
                      />
                      <label htmlFor="" className="w-max">
                        {option}
                      </label>
                    </li>
                  );
                })}
              </ul>
            </section>
          )}
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default SectionTabs;
