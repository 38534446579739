import React, { useState } from "react";
import {
  GetEvidenceAnalysis,
  StartEvidenceAnalysis,
} from "../../../../../services/audit-management";
import {
  faChartBar,
  faCheck,
  faRefresh,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import ModalLayout from "src/layouts/ModalLayout";

const Analysis = ({
  auditID,
  controlID,
}: {
  auditID: string;
  controlID: string;
}) => {
  const [show, setShow] = useState<boolean>(false);

  const startAnalysis = StartEvidenceAnalysis(auditID, controlID);
  const {
    data: analysis,
    status: analysisStatus,
    refetch,
  } = GetEvidenceAnalysis(auditID, controlID);

  const completed = analysis?.response;

  const handleOnClose = () => setShow(false);

  return (
    <>
      <button
        disabled={startAnalysis.status === "success" && !completed}
        className="flex items-center gap-2 px-4 py-2 w-max cursor-pointer text-b2-reg dark:text-white dark:disabled:text-gray-300 dark:disabled:bg-gray-800 dark:bg-black/40 dark:hover:bg-black/70 duration-100 rounded-xl"
        onClick={() => {
          setShow(true);
          startAnalysis.mutate({});
        }}
      >
        <FontAwesomeIcon icon={faChartBar} />
        <h4>Start Analysis</h4>
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="flex flex-col flex-grow gap-5">
          <h3 className="flex items-center gap-2 text-t1-semi">
            Evidence Analysis
          </h3>
          {completed && (
            <article className="flex items-center gap-5">
              <p>
                <FontAwesomeIcon icon={faCheck} className="text-yellow-500" />{" "}
                Analysis Completed
              </p>
              <button
                className="flex items-center gap-1 dark:hover:text-no/60 duration-100"
                onClick={() => {
                  startAnalysis.mutate({});
                  refetch();
                }}
              >
                <FontAwesomeIcon icon={faRefresh} className="text-no" />
                <h4>Re-run Analysis</h4>
              </button>
            </article>
          )}
          <section className="flex flex-col flex-grow gap-2">
            {analysisStatus === "success" ? (
              analysis ? (
                <section className="grid gap-2">
                  <h4>Analysis</h4>
                  <ReactMarkdown
                    className="min-w-full p-2 dark:text-black dark:bg-white prose overflow-auto rounded-md"
                    remarkPlugins={[remarkGfm]}
                  >
                    {analysis.response}
                  </ReactMarkdown>
                </section>
              ) : (
                <article className="flex flex-wrap items-center gap-5">
                  Analysis not available yet{" "}
                  <button
                    className="flex items-center gap-1 dark:hover:text-no/60 duration-100"
                    onClick={() => refetch()}
                  >
                    <FontAwesomeIcon icon={faRefresh} className="text-no" />
                    Refresh
                  </button>
                </article>
              )
            ) : null}
          </section>
        </section>
      </ModalLayout>
    </>
  );
};

export default Analysis;
