import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { AddGlobalQuestion } from "src/services/third-party-risk/questions/global-questions";
import RegularInput from "src/components/Input/RegularInput";
import ModalLayout from "src/layouts/ModalLayout";
import { GetQuestionTypes } from "../../../../../services/third-party-risk/questions/global-questions";

const AskGlobalQuestion = () => {
  const [show, setShow] = useState<boolean>(false);
  const [inputs, setInputs] = useState<any>({
    question: "",
    question_type: "Text",
  });

  const addQuestion = AddGlobalQuestion();
  const { data: questionTypes } = GetQuestionTypes();

  const handleOnClose = () => setShow(false);

  return (
    <>
      <button
        className="black-button"
        onClick={() => {
          setShow(true);
          setInputs({
            question: "",
            question_type: "",
          });
        }}
      >
        <FontAwesomeIcon icon={faPlus} />
        <h4>Add a Question</h4>
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="grid content-start gap-5 h-full">
          <h3 className="flex items-center gap-2 text-t1-semi">
            Add a Question
          </h3>
          <RegularInput
            label="Question"
            keyName="question"
            inputs={inputs}
            setInputs={setInputs}
          />
          <section className="grid gap-2 text-b2-reg">
            <h4>Question Type</h4>
            <section className="flex items-center gap-5">
              {questionTypes?.fields.map((questionType) => {
                return (
                  <article
                    key={questionType}
                    className="flex items-center gap-1"
                  >
                    <input
                      type="radio"
                      className="form-radio w-4 h-4 dark:bg-transparent dark:ring-0 dark:text-blue-500 dark:focus:border-blue-600 focus:ring dark:focus:ring-offset-0 dark:focus:ring-blue-600 focus:ring-opacity-50 rounded-full"
                      checked={inputs.question_type === questionType}
                      onChange={() =>
                        setInputs({
                          ...inputs,
                          question_type: questionType,
                        })
                      }
                    />
                    <label htmlFor="">
                      {questionType === "Yes_No" ? "Y/N" : questionType}
                    </label>
                  </article>
                );
              })}
            </section>
          </section>
          <article className="flex items-center place-content-end gap-5">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={inputs.question === "" || inputs.question_type === ""}
              className="blue-button"
              onClick={() => {
                addQuestion.mutate({
                  question: inputs.question,
                  question_type: inputs.question_type,
                });
                handleOnClose();
              }}
            >
              <h4>Done</h4>
            </button>
          </article>
        </section>
      </ModalLayout>
    </>
  );
};

export default AskGlobalQuestion;
