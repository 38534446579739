/* eslint-disable react-hooks/exhaustive-deps */
import {
  faChevronDown,
  faChevronRight,
  faWarning,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Disclosure, Transition } from "@headlessui/react";
import React from "react";
import DeleteVendorResponse from "./DeleteVendorResponse";
import Notes from "./Notes";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const Response = ({
  vendorID,
  assessmentID,
  questionIndex,
  qa,
}: {
  vendorID: string;
  assessmentID: string;
  questionIndex: number;
  qa: any;
}) => {
  const questionID = qa.generated_id;

  return (
    <li
      key={questionIndex}
      className="grid gap-2 p-5 w-full dark:bg-gray-700 rounded"
    >
      <header className="grid gap-5 w-full">
        <article className="flex items-center justify-between gap-10 w-full">
          <Notes
            vendorID={vendorID}
            assessmentID={assessmentID}
            questionID={questionID}
            question={qa}
          />
          <DeleteVendorResponse
            vendorID={vendorID}
            assessmentID={assessmentID}
            questionID={questionID}
          />
        </article>

        <p className="p-4 text-b2-reg dark:bg-gray-900 rounded">
          Q: {qa.question}
        </p>
      </header>
      {qa.question_type === "Yes_No" ? (
        <section className="flex items-center gap-5">
          {["Yes", "No"].map((questionType) => {
            return (
              <article key={questionType} className="flex items-center gap-1">
                <input
                  type="radio"
                  disabled
                  className="form-radio w-4 h-4 dark:bg-transparent dark:ring-0 dark:text-blue-500 dark:focus:border-blue-600 focus:ring dark:focus:ring-offset-0 dark:focus:ring-blue-600 focus:ring-opacity-50 rounded-full"
                  checked={
                    qa.response?.toLowerCase() === questionType.toLowerCase()
                  }
                />
                <label htmlFor="">
                  {questionType === "Yes_No" ? "Y/N" : questionType}
                </label>
              </article>
            );
          })}
          {!["yes", "no"].includes(qa.response?.toLowerCase()) && (
            <article>
              <FontAwesomeIcon icon={faWarning} className="text-red-700" />{" "}
              Invalid response
            </article>
          )}
        </section>
      ) : (
        <p className="p-4 text-b2-reg dark:bg-gray-900 rounded">
          A: {qa.response}
        </p>
      )}
      {qa.llm_response && (
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex items-center gap-1 w-max text-b2-reg">
                {open ? "Hide" : "Show"} Response Analysis
                <FontAwesomeIcon
                  icon={open ? faChevronDown : faChevronRight}
                  className="dark:text-white"
                />
              </Disclosure.Button>
              <Transition
                show={open}
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                <Disclosure.Panel>
                  <ReactMarkdown
                    className="min-w-full prose px-4 py-2 w-full text-b2-reg dark:text-black dark:bg-white resize-none rounded overflow-auto"
                    remarkPlugins={[remarkGfm]}
                  >
                    {qa.llm_response}
                  </ReactMarkdown>
                </Disclosure.Panel>
              </Transition>
            </>
          )}
        </Disclosure>
      )}
    </li>
  );
};

export default Response;
