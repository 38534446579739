/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import ReturnPage from "src/components/Button/ReturnPage";
import PageLayout from "src/layouts/PageLayout";
import DocumentMetadata from "./DocumentMetadata/DocumentMetadata";
import DocumentDetail from "./DocumentDetail/DocumentDetail";
import { parseURL } from "src/utils/general";
import { GetPolicyGroups } from "src/services/regulation-policy/policy";
import { KeyStringVal } from "src/types/general";

const Document = () => {
  const parsed = parseURL();

  const [selectedPolicyVersion, setSelectedPolicyVersion] =
    useState<string>("");
  const [editSections, setEditSections] = useState<any>({});

  const { data: policyGroups } = GetPolicyGroups();

  const policyGroup = policyGroups?.find(
    (group: KeyStringVal) =>
      group.policy_group_id === sessionStorage.selectedPolicyGroupID
  )?.title;
  const documentType = String(parsed.document_type) || "";
  const documentID = String(parsed.document_id) || "";

  return (
    <PageLayout>
      <main className="relative flex flex-col flex-grow gap-5 px-5 pt-5 h-full w-full overflow-auto scrollbar">
        <article className="flex items-center gap-2">
          <ReturnPage />
          <h4 className="flex items-center gap-2 text-b3-reg">
            <span className="dark:text-gray-500">Regulation & Policy</span>{" "}
            <span>/</span>
            <span className="capitalize">
              {documentType === "policies" ? (
                <span>Policy Group {policyGroup}</span>
              ) : (
                <span>{documentType.replace("s", "")}</span>
              )}
            </span>
          </h4>
        </article>
        <DocumentMetadata
          documentType={documentType}
          documentID={documentID}
          selectedPolicyVersion={selectedPolicyVersion}
          setSelectedPolicyVersion={setSelectedPolicyVersion}
        />
        <DocumentDetail
          documentType={documentType}
          documentID={documentID}
          editSections={editSections}
          setEditSections={setEditSections}
        />
      </main>
    </PageLayout>
  );
};

export default Document;
